import React from 'react'
import filterable from './HOC/filter'
import {Form} from 'react-final-form'
import Paper from '@material-ui/core/Paper'
import FilterActions from './FilterActions'

const Filter = ({
  children,
  filter: FilterForm,
  onClear,
  labels,
  formatFilters,
  onSubmit,
  initialValues
}) => {

  const formClear = (event, form) => {
    form.reset(event)
    onClear(event)
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {
        ({handleSubmit, form}) => (
          <Paper style={{padding: 10, marginBottom: 20}}>
            <form onSubmit={handleSubmit}>
              {children}
              <FilterActions
                onClear={(e) => formClear(e, form)}
                labels={labels} />
            </form>
          </Paper>
        )
      }
    </Form>
  )
}

export default filterable(Filter)
