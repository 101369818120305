import React from 'react'
import Grid from '@material-ui/core/Grid'
import {CrudForm} from '../../lib/CrudComponents'
import {TextField} from 'final-form-material-ui'
import {Field} from 'react-final-form'
import {FormApi} from '../../lib/Api'
import {sharedBoxApi, departmentApi} from '../../services/Api'
import {loopbackFilters} from '../../lib/Api/loopback'
import {MountEntity, toOption} from '../../lib/Api/Entity'
import Autocomplete from '../../lib/Fields/Autocomplete'

const loadDepartments = async (inputValue) => {
  const response = await departmentApi.getList({
    where: {
      name: loopbackFilters.ilikeWithoutSplit(inputValue)
    },
    order: 'name asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('name'))
  }
  return []
}

function SharedBoxForm({classes, ...props}) {
  const validate = values => ({
    department: values.department ? undefined : 'Obrigatório',
    email: values.email ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({id, data, handleSubmit}) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        departmentId: data.department.id,
        department: undefined
      }
    })
    return result
  }

  const getItemDecorator = async ({getItem}) => {
    const searchParams = new URLSearchParams(props.location.search)
    if (props.match.params.id === 'new' && searchParams.get('departmentId')) {
      const response = await departmentApi.getOne(searchParams.get('departmentId'))
      if (response.ok) {
        return {
          department: toOption('name')(response.data)
        }
      }
      return
    }

    return MountEntity(props, sharedBoxApi.getOne)
      .populate(({ data, id }) => ({
        department: departmentApi.getOne(data.departmentId)
      })).clean(['departmentId'])
      .map(data => ({
        ...data,
        department: toOption('name')(data.department)
      }))
      .getItem()
  }

  return (
    <FormApi
      {...props}
      getItemDecorator={getItemDecorator}
      submitDecorator={submitDecorator}
      api={sharedBoxApi} >
      {
        ({handleSubmit, getItem}) => (
          <CrudForm
            validate={validate}
            history={props.history}
            getItem={getItem}
            onSubmit={handleSubmit}>
            <Grid direction='row' container spacing={16}>
              <Grid item sm={6}>
                <Field
                  label='Departamento'
                  loadOptions={loadDepartments}
                  component={Autocomplete}
                  name='department' />
              </Grid>
              <Grid item sm={6}>
                <Field
                  fullWidth
                  type='email'
                  component={TextField}
                  label='Email'
                  name='email' />
              </Grid>
            </Grid>
          </CrudForm>
        )
      }
    </FormApi>
  )
}

export default SharedBoxForm
