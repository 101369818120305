export const getRowsPerPage = () => {
  return localStorage['rowsPerPage'] || 10
}

export const setRowsPerPage = (rowsPerPage) => {
  localStorage['rowsPerPage'] = rowsPerPage
}

export const getOrder = () => {
  return localStorage['order'] || 'created'
}

export const setOrder = (order) => {
  localStorage['order'] = order
}