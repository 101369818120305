import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ReplyAll from '@material-ui/icons/ReplyAll'
import { CrudForm } from '../../lib/CrudComponents'
import { FormApi } from '../../lib/Api'
import DateTimePicker from '../../lib/Fields/DateTimePicker'
import Checkbox from '../../lib/Common/Checkbox'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import {
  ticketApi, statusApi, sharedBoxApi, categoryApi, messageApi
} from '../../services/Api'
import MessageItem from '../Message/MessageItem'
import List from '@material-ui/core/List'
import StatusSelect from './StatusSelect'
import TicketLogs from './TicketLogs'
import SelectField from '../../lib/Fields/Select'
import Snackbar from '../../lib/Common/Snackbar'
import moment from 'moment'
import { Paper } from '@material-ui/core'

const styles = theme => ({
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing.unit * 4
  },
  tickets: {
    marginTop: theme.spacing.unit * -4,
    marginBottom: theme.spacing.unit * 4
  }
})

function TicketForm({ classes, ...props }) {
  const [item, setItem] = useState()
  const [message, setMessage] = useState('')
  const [statuses, setStatuses] = useState([])
  const [responsibles, setResponsibles] = useState([])
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])
  const [rerenderLogs, setRerenderLogs] = useState(false)

  function handleSanckbarClose() {
    setMessage('')
  }

  const validate = values => ({
    name: values.name ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        lastUpdate: new Date()
      }
    })
    if (result.ok) {
      setItem(data)
      setRerenderLogs(!rerenderLogs)
      return result
    }
    setMessage('Necessário Categoria e Subcategoria')
    return {
      ok: false
    }
  }

  const getItemDecorator = async ({ getItem }) => {
    const params = new URLSearchParams(props.location.search)
    setMessage(params.get('message'))
    const statusId = params.get('statusId')
    const item = await getItem()
    const response = await statusApi.getList(
      { where: { sharedBoxId: item.sharedBoxId } })
    if (response.ok) {
      setStatuses(response.data)
    }
    const responseResposibles = await sharedBoxApi.listUserMails(
      item.sharedBoxId,
      { filter: { where: { removed: false }, orderBy: 'email ASC' } })
    if (responseResposibles.ok) {
      setResponsibles(responseResposibles.data)
    }
    if (item.categoryId) {
      const responseSubcategories = await categoryApi.listSubcategories(
        item.categoryId,
        { filter: { where: { removed: false }, orderBy: 'name ASC' } })
      if (responseSubcategories.ok) {
        setSubcategories(responseSubcategories.data)
      }
    }
    const responseCategories = await sharedBoxApi.listCategories(
      item.sharedBoxId,
      { filter: { where: { removed: false }, orderBy: 'name ASC' } })
    if (responseCategories.ok) {
      setCategories(responseCategories.data)
    }
    item.messages.sort((a, b) => {
      if (a.createdDateTime < b.createdDateTime) {
        return -1
      }
      return 0
    })
    setItem(item)
    return {
      ...item,
      statusId: statusId ? statusId : item.statusId
    }
  }

  const renderMessages = (messages) => {
    const stortedMessages = messages.sort((m1, m2) => moment(m1.createdDateTime).isAfter(m2.createdDateTime) ? -1 : 1)
    const renderedMessages = stortedMessages.map(element => {
      return (
        <MessageItem
          key={element.id}
          subject={element.subject}
          type={element.type}
          from={element.from}
          isRead={element.isRead}
          created={element.createdDateTime}
          onClick={() => props.history.push(`/messages/${element.id}`)} />)
    })
    return (renderedMessages)
  }

  const categoryChange = async (event) => {
    const categoryId = event.target.value
    if (categoryId) {
      const responseSubcategories = await categoryApi.listSubcategories(
        categoryId,
        { 'filter': { orderBy: 'name ASC' } })
      if (responseSubcategories.ok) {
        setSubcategories(responseSubcategories.data)
      } else {
        setSubcategories([])
      }
    } else {
      setSubcategories([])
    }
  }

  const customActions = [
    {
      label: 'Responder',
      icon: <ReplyAll style={{ marginLeft: 10 }} />,
      onClick: async () => {
        const lastMessage = item.messages[0]
        const response = await messageApi.createReplyAll(lastMessage.id, { ticketId: lastMessage.ticketId })
        if (response.ok) {
          props.history.push(`/messages-edit?id=${response.data.id}`)
        } else {
          const responseLastMessage = await messageApi.getMessage(lastMessage.id)
          if (responseLastMessage.ok) {
            if (responseLastMessage.data.isDraft) {
              props.history.push(`/messages-edit?id=${responseLastMessage.data.id}`)
            }
          }
          setMessage('Ocorreu um problema ao tentar responder!')
        }
      }
    }]

  const showCustomAction = () => {
    return item && item.statusId
  }

  return (
    <div>
      <FormApi
        {...props}
        getItemDecorator={getItemDecorator}
        submitDecorator={submitDecorator}
        api={ticketApi} >
        {
          ({ handleSubmit, getItem }) => (
            <Paper>
              <CrudForm
                validate={validate}
                history={props.history}
                getItem={getItem}
                customActions={showCustomAction() ? customActions : []}
                onSubmit={handleSubmit}>
                <Grid container spacing={16}>
                  <Grid item sm={6}>
                    <Field
                      fullWidth
                      component={TextField}
                      label='Assunto/Ticket'
                      name='name' />
                  </Grid>
                  <Grid item sm={2}>
                    <Field
                      fullWidth
                      disabled
                      component={DateTimePicker}
                      label='Criação'
                      name='created' />
                  </Grid>
                  <Grid item sm={2}>
                    <Field
                      fullWidth
                      disabled
                      component={DateTimePicker}
                      label='Última Modificação'
                      name='lastUpdate' />
                  </Grid>
                  <Grid item sm={2}>
                    <Field
                      type='checkbox'
                      label='Destacado'
                      component={Checkbox}
                      name='highlight' />
                  </Grid>
                  <Grid item sm={2}>
                    <Field
                      fullWidth
                      component={StatusSelect}
                      options={statuses.map(status => ({
                        value: status.id, label: status.label, color: status.color
                      }))}
                      label='Status'
                      name='statusId' />
                  </Grid>
                  <Grid item sm={3}>
                    <Field
                      fullWidth
                      hideEmpty
                      component={SelectField}
                      options={responsibles.map(responsible => ({
                        value: responsible.id, label: responsible.email
                      }))}
                      label='Responsável'
                      name='responsibleId' />
                  </Grid>
                  <Grid item sm={2}>
                    <Field
                      fullWidth
                      onChange={(e) => categoryChange(e)}
                      component={SelectField}
                      options={categories.map(category => ({
                        value: category.id, label: category.name
                      }))}
                      label='Categoria'
                      name='categoryId' />
                  </Grid>
                  <Grid item sm={2}>
                    <Field
                      fullWidth
                      disabled={subcategories.length === 0}
                      component={SelectField}
                      options={subcategories.map(subcategory => ({
                        value: subcategory.id, label: subcategory.name
                      }))}
                      label='Subcategoria'
                      name='subcategoryId' />
                  </Grid>
                  <Grid item sm={3}>
                    <Field
                      fullWidth
                      component={TextField}
                      label='Identificador'
                      name='identifier' />
                  </Grid>
                </Grid>
              </CrudForm>
              <Grid container className={classes.tickets}>
                <List className={classes.list}>
                  {item && renderMessages(item.messages)}
                </List>
              </Grid>
            </Paper>
          )
        }
      </FormApi>
      <TicketLogs {...props} rerender={rerenderLogs} />
      <Snackbar
        autoHideDuration={6000}
        onClose={handleSanckbarClose}
        message={message} />
    </div>
  )
}

export default withStyles(styles)(TicketForm)
