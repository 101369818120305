import React, { useState, useEffect } from 'react'
import { Paper, withStyles, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Form, Field } from 'react-final-form'
import classNames from 'classnames'
import { userMailApi } from '../../services/Api'
import Quill from '../../lib/Fields/Quill'

const styles = theme => ({
  root: {
    padding: 16,
    marginBottom: 20
  },
  body: {
    padding: 16
  },
  formContent: {
    paddingTop: 16,
    paddingBottom: 16,
    width: '100%'
  },
  buttonContent: {
    width: '100%'
  },
})

function UserMailSignatureForm({ classes, ...props }) {
  const [item, setItem] = useState()
  const [formState, setFormState] = useState({
    status: 'edit',
    message: ''
  })

  useEffect(() => {
    async function fetchData() {
      const response = await userMailApi.getSignature()
      if (response.ok) {
        setItem({
          signature: response.data.signature
        })
      }
    }
    fetchData()
  }, [props.history.location.pathname])

  const handleSubmit = async (data) => {
    const submitData = {
      signature: data.signature
    }
    const result = await userMailApi.setSignature(submitData)
    if (result) {
      setFormState({
        status: result.ok ? 'success' : 'error',
        message: result.message
      })
      setTimeout(() => setFormState({
        status: 'edit',
        ...formState
      }), 4000)
    }
  }

  const getButtonClass = () => {
    switch (formState.status) {
      case 'success':
        return classes.successButton
      case 'edit':
        return classes.button
      case 'error':
        return classes.errorButton
      default: throw Error('Unexpected formState')
    }
  }

  const getButtonLabel = (submitting) => {
    if (submitting) {
      return (
        <div>
          <CircularProgress size={24} className={classes.buttonProgress} />
          Salvando
        </div>
      )
    }
    switch (formState.status) {
      case 'success':
        return (
          [
            <CheckCircleIcon key='successIcon' className={classNames(classes.leftIcon, classes.iconSmall)} />,
            <span key='successLabel'>Salvo</span>
          ]
        )
      case 'edit':
        return 'Salvar'
      case 'error':
        return 'Oops, algo errado'
      default: throw Error('Unexpected formState')
    }
  }

  return (!!item) ?
    (<Paper className={classes.root}>
      <Form onSubmit={handleSubmit} initialValues={item}>
        {
          ({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} >
              <Grid direction='column' container spacing={16}>
                <Grid item>
                  <div className={classes.formContent}>
                    <Grid container spacing={16}>
                      <Grid item sm={12}>
                        <Field
                          fullWidth
                          component={Quill}
                          label='Assinatura'
                          name='signature' />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item sm={12} className={classes.buttonContent}>
                  <Grid justify='flex-end' container spacing={16}>
                    <Grid item classes={{ item: classes.buttonContainer }}>
                      <Button fullWidth type='button' onClick={() => props.history.goBack()}>
                        Voltar
                      </Button>
                    </Grid>
                    <Grid item classes={{ item: classes.buttonContainer }}>
                      <Button
                        className={getButtonClass()}
                        fullWidth
                        type='submit'
                        disabled={submitting}
                        variant='contained'
                        color='primary'>
                        {getButtonLabel(submitting)}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )
        }
      </Form>
    </Paper>)
    : <Grid container direction='row' justify="center" spacing={16}>
        <CircularProgress size={24} />
      </Grid>
}

export default withStyles(styles)(UserMailSignatureForm)
