import React from 'react'
import List from '@material-ui/core/List'
import { withStyles } from '@material-ui/core/styles'
import {compose} from 'recompose'
import {withRouter} from 'react-router-dom'
import MenuItem from './MenuItem';

const styles = theme => ({
  menuColor : {
    color: theme.palette.grey[500]
  },
  menuColorActive : {
    color: theme.palette.common.white
  },
  divider: {
    backgroundColor: '#9D9C9D'
  },
  active:{
    borderLeft: `solid 3px ${theme.palette.primary.main}`,
    borderImageSlice: 1
  },
  list: {
    width: '100%'
  },
})

class MenuItems extends React.Component {
  state = {open: ''}

  handleGroupClick = (label) => () => {
    this.setState({
      open: this.state.open === label ? '' : label
    })
  }

  render () {
    const {items = [], colapseAll} = this.props
    return (
      <List className={this.props.classes.list}>
        {items.map((item) =>
          <React.Fragment
            key={item.pathname}>
            <MenuItem
              {...item}
              history={this.props.history}
              colapseAll={colapseAll}
              onClick={() => this.props.history.push(item.path)}>
            </MenuItem>
          </React.Fragment>
        )}
      </List>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(MenuItems)
