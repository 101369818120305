import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { Paper, withStyles, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Quill from '../../lib/Fields/Quill'
import Autocomplete from '../../lib/Fields/Autocomplete'
import AutocompleteEmail from '../../lib/Fields/AutocompleteEmail'
import SimpleDialog from '../../lib/Common/SimpleDialog'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import classNames from 'classnames'
import {
  messageApi, userMailApi, attachmentApi, contactsApi
} from '../../services/Api'
import { loopbackFilters } from '../../lib/Api/loopback'
import { toOption } from '../../lib/Api/Entity'
import SimpleFileUpload from '../Attachment/SimpleFileUpload'
import uuidv4 from 'uuid/v4'
import Snackbar from '../../lib/Common/Snackbar'

const MATCHEMAILADDRESS = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const styles = theme => ({
  root: {
    padding: 16,
    marginBottom: 20
  },
  body: {
    padding: 16
  },
  formContent: {
    paddingTop: 16,
    paddingBottom: 16,
    width: '100%'
  },
  buttonContent: {
    width: '100%'
  },
})

const loadContacts = async (inputValue) => {
  const response = await contactsApi.list(
    {
      query: {
        where: {
          email: loopbackFilters.ilikeWithoutSplit(inputValue)
        },
        order: 'email asc',
        limit: 10
      }
    })
  if (response.ok) {
    return response.data.map(toOption('email'))
  }
  return []
}

function MessageForm({ classes, ...props }) {
  const [item, setItem] = useState()
  const [open, setOpen] = useState()
  const [messageAttachment, setMessageAttachment] = useState()
  const [messageSend, setMessageSend] = useState()
  const [count, setCount] = useState(0)
  const [senders, setSenders] = useState()
  const [files, setFiles] = useState([])
  const [fetching, setFetching] = useState()
  const [formState, setFormState] = useState({
    status: 'edit',
    message: ''
  })

  const fetchSenders = async () => {
    const user = JSON.parse(localStorage['user'])
    const responseUserMail = await userMailApi.getOne(user.userId)
    if (responseUserMail.ok) {
      const userMails = [{
        label: responseUserMail.data.email,
        value: responseUserMail.data.email.toUpperCase()
      }]
      const responseSharedBoxes = await userMailApi.getSharedBoxes(user.userId)
      if (responseSharedBoxes.ok) {
        const sharedBoxes = responseSharedBoxes.data.map((sharedBox) => ({
          label: sharedBox.email,
          value: sharedBox.email.toUpperCase()
        }))
        setSenders(
          userMails.concat(sharedBoxes)
        )
      }
    }
  }

  const emailToObjects = (emails) => {
    if (emails) {
      return emails.split(',').map((r, i) => ({
        id: i,
        value: r.trim(),
        label: r.trim()
      }))
    }
    return []
  }

  useEffect(() => {
    async function fetchData(id) {
      const response = await messageApi.getMessage(id)
      if (response.ok) {
        const message = response.data
        await fetchSenders()
        const responseAttachments = await attachmentApi.getAttachments(response.data.id)
        let inlineAttachs = []
        if (responseAttachments.ok) {
          inlineAttachs = responseAttachments.data.filter(a => a.isInline)
          const attachments = (responseAttachments.data.filter(a => !a.isInline))
          setFiles(attachments.map((attachment) => ({
            id: attachment.id,
            name: attachment.name,
            data: `data:${attachment.contentType};base64,${attachment.contentBytes}`,
            size: attachment.size,
            type: attachment.contentType,
            lastModified: attachment.lastModifiedDateTime
          })))
        }
        let signature = ''
        const responseSignature = await userMailApi.getSignature()
        if (responseSignature.ok) {
          signature = responseSignature.data.signature
        }
        setItem({
          ...message,
          from: { label: message.from, value: message.from.toUpperCase },
          toRecipients: emailToObjects(message.toRecipients),
          ccRecipients: emailToObjects(message.ccRecipients),
          body: `<br/>${signature ? signature : ''}${processBody(message.body, inlineAttachs)}`
        })
      }
    }
    const searchParams = new URLSearchParams(props.location.search)
    fetchData(searchParams.get('id'))
  }, [props.history.location.pathname])

  function validateEmails(emailsObject, canEmpty = false) {
    if (canEmpty && !emailsObject) return undefined
    if (typeof emailsObject === 'object' && emailsObject) {
      const emails = emailsObject.map(e => e.label)
      for (const email of emails) {
        if (!MATCHEMAILADDRESS.test(email)) {
          return 'Email Inválido'
        }
      }
      return undefined
    }
    return 'Obrigatório'
  }

  const validate = values => ({
    toRecipients: validateEmails(values.toRecipients),
    ccRecipients: validateEmails(values.ccRecipients, true),
    bccRecipients: validateEmails(values.bccRecipients, true),
    from: values.from ? undefined : 'Obrigatório'
  })

  const processBodyToSend = (body) => {
    var htmlObject = document.createElement('div')
    htmlObject.innerHTML = body
    const images = htmlObject.getElementsByTagName('img')
    const attachs = []
    for (const image of images) {
      const cid = uuidv4()
      const fullContent = image.src
      image.src = `cid:${cid}`
      const name = `Anexo ${cid}`
      const content = fullContent.split(',').pop()
      attachs.push({
        name,
        content,
        contentId: cid,
        isInline: true
      })
    }
    return { body: htmlObject.outerHTML, attachments: attachs }
  }

  const handleSubmit = async (data) => {
    setMessageAttachment('Enviando anexos inline')
    const { body, attachments } = processBodyToSend(data.body)
    const totalAttachments = attachments.length
    const toRecipients = data.toRecipients.map(r => r.label).join(', ')
    const ccRecipients = data.ccRecipients ? data.ccRecipients.map(r => r.label).join(', ') : ''
    const bccRecipients = data.bccRecipients ? data.bccRecipients.map(r => r.label).join(', ') : ''
    const promises = attachments.map(
      (attachment) => (
        attachmentApi.createAttachment(item.id, attachment)
          .then(() => {
            setCount(prevCount => {
              const newCount = prevCount + 1
              setMessageAttachment(`Enviando anexos inline: ${newCount} de ${totalAttachments}`)
              return newCount
            })
          })
      )
    )
    await Promise.all(promises)
    setMessageAttachment('')
    setMessageSend('Enviando conteúdo do email')
    const submitData = {
      from: data.from.label,
      toRecipients,
      ccRecipients,
      bccRecipients,
      subject: data.subject,
      body: body,
      bodyType: data.bodyType
    }
    const result = await messageApi.updateMessage(item.id, submitData)
    if (result.ok) {
      setMessageSend('')
      const resultSend = await messageApi.send(item.id)
      if (resultSend.ok) {
        setFormState({
          status: 'success',
          message: 'Mensagem enviada'
        })
        setTimeout(() =>
          props.history.goBack()
          , 2000)
      }
    } else {
      setFormState({
        status: 'error',
        message: result.message
      })
      setTimeout(() => setFormState({
        status: 'edit',
        ...formState
      }), 4000)
    }
  }

  const getButtonClass = () => {
    switch (formState.status) {
      case 'success':
        return classes.successButton
      case 'edit':
        return classes.button
      case 'error':
        return classes.errorButton
      case 'fetching':
        return classes.fetchingButton
      default: throw Error('Unexpected formState')
    }
  }

  const getButtonLabel = (submitting) => {
    if (submitting) {
      return (
        <div>
          <CircularProgress size={24} className={classes.buttonProgress} />
          Enviando
        </div>
      )
    }
    switch (formState.status) {
      case 'success':
        return (
          [
            <CheckCircleIcon key='successIcon' className={classNames(classes.leftIcon, classes.iconSmall)} />,
            <span key='successLabel'>Enviado</span>
          ]
        )
      case 'edit':
        return 'Enviar'
      case 'fetching':
        return 'Carregando anexo'
      case 'error':
        return 'Oops, algo errado'
      default: throw Error('Unexpected formState')
    }
  }

  const loadSenders = inputValue => {
    const values = R.pipe(
      R.filter(sender => new RegExp(inputValue.toUpperCase()).test(sender.value)),
      R.take(5)
    )(senders)
    return Promise.resolve(values)
  }

  const deleteDraft = async () => {
    const response = await messageApi.deleteMessage(item.id)
    if (response.ok) {
      props.history.goBack()
    } else {
      setFormState({
        status: 'error',
        message: response.message
      })
    }
  }

  function replaceAll(str, search, replacement) {
    return str.split(search).join(replacement)
  }

  function processBody(body, inlineAttachs) {
    for (const inline of inlineAttachs) {
      const base = `data:${inline.contentType};base64,${inline.contentBytes}`
      body = replaceAll(body, `cid:${inline.contentId}`, base)
    }
    return body
  }

  const fetchingAttachment = (fetch) => {
    setFetching(fetch)
    setFormState({
      status: fetch ? 'fetching' : 'edit'
    })
  }

  return (!!item) ?
    (<Paper className={classes.root}>
      <Form onSubmit={handleSubmit} validate={validate} initialValues={item}>
        {
          ({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} >
              <Grid direction='column' container spacing={16}>
                <Grid item>
                  <div className={classes.formContent}>
                    <Grid container spacing={16}>
                      <Grid item sm={12}>
                        <Field
                          fullWidth
                          loadOptions={loadSenders}
                          component={Autocomplete}
                          label='De'
                          name='from' />
                      </Grid>
                      <Grid item sm={12}>
                        <Field
                          fullWidth
                          isMulti
                          allowCreate
                          loadOptions={loadContacts}
                          component={AutocompleteEmail}
                          label='Para'
                          name='toRecipients' />
                      </Grid>
                      <Grid item sm={12}>
                        <Field
                          fullWidth
                          isMulti
                          allowCreate
                          loadOptions={loadContacts}
                          component={AutocompleteEmail}
                          label='CC'
                          name='ccRecipients' />
                      </Grid>
                      <Grid item sm={12}>
                        <Field
                          fullWidth
                          isMulti
                          allowCreate
                          loadOptions={loadContacts}
                          component={AutocompleteEmail}
                          label='CCo'
                          name='bccRecipients' />
                      </Grid>
                      <Grid item sm={12}>
                        <Field
                          fullWidth
                          component={TextField}
                          label='Assunto'
                          name='subject' />
                      </Grid>
                      <Grid item sm={12}>
                        <SimpleFileUpload
                          fetching={fetchingAttachment}
                          messageId={item.id}
                          initialFiles={files}
                          handleFilesChange={setFiles} />
                      </Grid>
                      <Grid item xs={12} className={classes.buttonContent}>
                        <Grid justify='flex-end' container spacing={16}>
                          <Grid item classes={{ item: classes.buttonContainer }}>
                            <Button fullWidth type='button' onClick={() => setOpen(true)}>
                              Descartar
                          </Button>
                          </Grid>
                          <Grid item classes={{ item: classes.buttonContainer }}>
                            <Button
                              className={getButtonClass()}
                              fullWidth
                              type='submit'
                              disabled={(submitting || fetching)}
                              variant='contained'
                              color='primary'>
                              {getButtonLabel(submitting)}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12}>
                        <Field
                          autoFocus
                          component={Quill}
                          name='body'
                          label='Email' />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <SimpleDialog
                  open={!!open}
                  buttonLabel='Não'
                  handleClose={() => setOpen(false)}
                  primaryAction={deleteDraft}
                  title='Deseja excluir o rascunho?'
                  primaryActionButtonLabel='Sim' />
              </Grid>
            </form>
          )
        }
      </Form>
      <Snackbar
        onClose={() => setMessageAttachment('')}
        message={messageAttachment} />
      <Snackbar
        autoHideDuration={6000}
        onClose={() => setMessageSend('')}
        message={messageSend} />
    </Paper>)
    : <Grid container direction='row' justify="center" spacing={16}>
      <CircularProgress size={24} />
    </Grid>
}

export default withStyles(styles)(MessageForm)
