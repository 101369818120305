import React, { useState } from 'react'
import { Paper, withStyles, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import classNames from 'classnames'
import { sharedBoxApi } from '../../services/Api'

const styles = theme => ({
  root: {
    padding: 16,
    marginBottom: 20
  },
  body: {
    padding: 16
  },
  formContent: {
    paddingTop: 16,
    paddingBottom: 16,
    width: '100%'
  },
  buttonContent: {
    width: '100%'
  },
})

function SharedBoxRelation({ classes, ...props }) {
  const [formState, setFormState] = useState({
    status: 'edit',
    message: ''
  })

  const validate = values => ({
    email: values.email ? undefined : 'Obrigatório'
  })

  const handleSubmit = async (data) => {
    const submitData = {
      email: data.email
    }
    const result = await sharedBoxApi.addRelation(submitData)
    if (result.ok) {
      props.history.push(`sharedBoxes/${result.data.id}`)
    } else {
      setFormState({
        status: 'error',
        message: result.data.error.message
      })
      setTimeout(() => setFormState({
        status: 'edit',
        ...formState
      }), 4000)
    }
  }

  const getButtonClass = () => {
    switch (formState.status) {
      case 'success':
        return classes.successButton
      case 'edit':
        return classes.button
      case 'error':
        return classes.errorButton
      default: throw Error('Unexpected formState')
    }
  }

  const getButtonLabel = (submitting) => {
    if (submitting) {
      return (
        <div>
          <CircularProgress size={24} className={classes.buttonProgress} />
          Enviando
        </div>
      )
    }
    switch (formState.status) {
      case 'success':
        return (
          [
            <CheckCircleIcon key='successIcon' className={classNames(classes.leftIcon, classes.iconSmall)} />,
            <span key='successLabel'>Enviado</span>
          ]
        )
      case 'edit':
        return 'Enviar'
      case 'error':
        return 'Oops, algo errado'
      default: throw Error('Unexpected formState')
    }
  }

  return (
    <Paper className={classes.root}>
      <Form onSubmit={handleSubmit} validate={validate}>
        {
          ({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} >
              <Grid direction='column' container spacing={16}>
                <Grid item>
                  <div className={classes.formContent}>
                    <Grid container spacing={16}>
                      <Grid item sm={12}>
                        <Field
                          fullWidth
                          type='email'
                          component={TextField}
                          label='Email'
                          name='email' />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item sm={12} className={classes.buttonContent}>
                  <Grid justify='flex-end' container spacing={16}>
                    <Grid item classes={{ item: classes.buttonContainer }}>
                      <Button fullWidth type='button' onClick={() => props.history.goBack()}>
                        Voltar
                      </Button>
                    </Grid>
                    <Grid item classes={{ item: classes.buttonContainer }}>
                      <Button
                        className={getButtonClass()}
                        fullWidth
                        type='submit'
                        disabled={submitting}
                        variant='contained'
                        color='primary'>
                        {getButtonLabel(submitting)}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )
        }
      </Form>
    </Paper>)
}

export default withStyles(styles)(SharedBoxRelation)
