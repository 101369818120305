import React from 'react'
import {Login} from '../../lib/Login'
import {customLogin, setToken} from '../../services/Api'
import config from '../../Config'
import Snackbar from '../../lib/Common/Snackbar'
import logo from '../../images/g10-login.png'

class CustomLogin extends React.Component {
  state = {
    message: ''
  }

  handleLogin = async ({username}) => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    const response = await customLogin({
      username,
      code
    })
    if (response.ok) {
      const {accessToken, userMail} = response.data
      setToken(accessToken.id)
      return {
        ok: true,
        token: accessToken.id,
        userId: accessToken.userId,
        realm: userMail.realm,
        name: username
      }
    } else if (response.problem === 'NETWORK_ERROR') {
      return {
        ok: false,
        message: 'Servidor não encontrado'
      }
    } else if (response.problem === 'SERVER_ERROR') {
      const msg = 'Ocorreu um erro, por favor verifique e/ou resete suas credenciais!'
      window.location.href = `${config.REDIRECT_URI}?message=${msg}`
      return {
        ok: false
      }
    } else if (response.data.error.code === 'MODEL_NOT_FOUND') {
      return {
        ok: false,
        message: 'Usuário não cadastrado!'
      }
    }
    const uri = config.REDIRECT_URI
    const clientId = config.CLIENT_ID
    const scope = 'openid mail.readwrite mail.readwrite.shared mail.send mail.send.shared offline_access'
    const path = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${uri}&response_type=code&scope=${scope}&login_hint=${username}&state=${username}`
    window.location.href = path
    return {
      ok: false,
      message: 'Vamos redirecionar você para obter autorização!'
    }
  }

  componentDidMount(props) {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    const state = url.searchParams.get('state')
    const msg = url.searchParams.get('message')
    if (code && state) {
      this.setState({message: 'Aguarde, estamos processando seu login'})
      this.loginComponent.submitForm()
    }
    if (msg){
      this.setState({message: msg})
    }
  }

  handleSanckbarClose = () => {
    this.setState({message: ''})
  }

  render () {
    const {history} = this.props
    return (<React.Fragment>
      <Login
        ref={ref => this.loginComponent = ref}
        history={history}
        onSubmit={this.handleLogin}
        requiredLabel='Obrigatório'
        usernameLabel='Usuário'
        submitLabel='Entrar'
        passwordLabel='Senha'
        recoverPasswordLabel='Resetar Credenciais'
        logo={<img alt='logo' src={logo} />}/>
      <Snackbar
        autoHideDuration={6000}
        onClose={this.handleSanckbarClose}
        message={this.state.message} />
    </React.Fragment>)
  }
}

export default (CustomLogin)
