import React from 'react'
import { DateTimePicker } from "material-ui-pickers";

export default function DateTimePickerField({input, meta, ...props}) {

  return (
    <DateTimePicker
      {...props}
      keyboard
      clearable
      format='DD/MM/YYYY HH:mm'
      mask={value =>
        value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/] : []
      }
      value={input.value || null}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      onChange={date => {
        input.onChange(date ? date.toISOString() : undefined)
      }}
      disableOpenOnEnter
      animateYearScrolling={false}
      ampm={false}
    />
  )
}
