import React from 'react'
import Grid from '@material-ui/core/Grid'
import { CrudForm } from '../../lib/CrudComponents'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { FormApi } from '../../lib/Api'
import { userMailApi } from '../../services/Api'
import SelectField from '../../lib/Fields/Select'
import crypto from 'crypto'

function UserMailForm({ classes, ...props }) {
  const validate = values => ({
    email: values.email ? undefined : 'Obrigatório',
    real: values.realm ? undefined : 'Obrigatório',
  })

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const password = crypto.randomBytes(8).toString('hex')
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        password
      }
    })
    return result
  }

  const getItemDecorator = async ({ getItem }) => {
    return await getItem()
  }

  return (
    <FormApi
      {...props}
      getItemDecorator={getItemDecorator}
      submitDecorator={submitDecorator}
      api={userMailApi} >
      {
        ({ handleSubmit, getItem }) => (
          <CrudForm
            withPaper
            validate={validate}
            history={props.history}
            getItem={getItem}
            onSubmit={handleSubmit}>
            <Grid direction='row' container spacing={16}>
              <Grid item sm={6}>
                <Field
                  fullWidth
                  type='email'
                  component={TextField}
                  label='Email'
                  name='email' />
              </Grid>
              <Grid item sm={6}>
                <Field
                  fullWidth
                  hideEmpty
                  component={SelectField}
                  options={[
                    {value: 'user', label: 'Usuário'},
                    {value: 'admin', label: 'Administrador'}
                  ]}
                  label='Permissão'
                  name='realm' />
              </Grid>
            </Grid>
          </CrudForm>
        )
      }
    </FormApi>
  )
}

export default UserMailForm
