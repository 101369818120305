import React from 'react'
import Grid from '@material-ui/core/Grid'
import {CrudForm} from '../../lib/CrudComponents'
import {TextField} from 'final-form-material-ui'
import {Field} from 'react-final-form'
import {FormApi} from '../../lib/Api'
import {categoryApi, sharedBoxApi} from '../../services/Api'
import {loopbackFilters} from '../../lib/Api/loopback'
import {MountEntity, toOption} from '../../lib/Api/Entity'
import Autocomplete from '../../lib/Fields/Autocomplete'

const loadSharedBoxes = async (inputValue) => {
  const response = await sharedBoxApi.getList({
    where: {
      email: loopbackFilters.ilikeWithoutSplit(inputValue)
    },
    order: 'email asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('email'))
  }
  return []
}

function CategoryForm({classes, ...props}) {
  const validate = values => ({
    sharedBox: values.sharedBox ? undefined : 'Obrigatório',
    name: (values.name && values.name.trim()) ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({id, data, handleSubmit}) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        sharedBoxId: data.sharedBox.id,
        sharedBox: undefined
      }
    })
    return result
  }

  const getItemDecorator = async ({getItem}) => {
    const searchParams = new URLSearchParams(props.location.search)
    if (props.match.params.id === 'new' && searchParams.get('sharedBoxId')) {
      const response = await sharedBoxApi.getOne(searchParams.get('sharedBoxId'))
      if (response.ok) {
        return {
          sharedBox: toOption('email')(response.data)
        }
      }
      return
    }

    return MountEntity(props, categoryApi.getOne)
      .populate(({ data, id }) => ({
        sharedBox: sharedBoxApi.getOne(data.sharedBoxId)
      })).clean(['sharedBoxId'])
      .map(data => ({
        ...data,
        sharedBox: toOption('email')(data.sharedBox)
      }))
      .getItem()
  }

  return (
    <FormApi
      {...props}
      getItemDecorator={getItemDecorator}
      submitDecorator={submitDecorator}
      api={categoryApi} >
      {
        ({handleSubmit, getItem}) => (
          <CrudForm
            validate={validate}
            history={props.history}
            getItem={getItem}
            onSubmit={handleSubmit}>
            <Grid direction='row' container spacing={16}>
              <Grid item sm={6}>
                <Field
                  label='Shared Box'
                  loadOptions={loadSharedBoxes}
                  component={Autocomplete}
                  name='sharedBox' />
              </Grid>
              <Grid item sm={6}>
                <Field
                  fullWidth
                  component={TextField}
                  label='Nome'
                  name='name' />
              </Grid>
            </Grid>
          </CrudForm>
        )
      }
    </FormApi>
  )
}

export default CategoryForm
