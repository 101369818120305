import React, {useEffect, useState} from 'react'
import { Paper, withStyles, Grid, IconButton, Tooltip } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Sync from '@material-ui/icons/Sync'
import Add from '@material-ui/icons/Add'
import {
  sharedBoxApi,
  userMailApi,
  messageApi
} from '../../services/Api'
import { TicketsList } from '../Ticket'

const styles = theme => ({
  root: {
    padding: 16,
    marginBottom: 20
  },
  containerList: {
    width: '100%',
    overflowX: 'auto',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingLeft: 16
  }
})

function SharedBoxCard({classes, ...props}) {
  const [item, setItem] = useState()
  const [fetching, setFetching] = useState()

  const getCurrentId = (params) => {
    if (params.childId) {
      return params.childId
    }
    return params.id
  }

  async function syncMailFolders () {
    await sharedBoxApi.syncAllMailFolders(item.id)
  }

  const syncMessages = async (userMailId) => {
    await sharedBoxApi.syncAllMessages(userMailId)
  }

  useEffect(() => {
    async function fetchData(id) {
      setFetching(true)
      const user = JSON.parse(localStorage['user'])
      const responseShareds = await userMailApi.getSharedBoxes(user.userId)
      if (responseShareds.ok) {
        const ids = responseShareds.data.map((sharedBox) => sharedBox.id)
        if (ids.includes(id)){
          const response = await sharedBoxApi.getOne(id)
          if (response.ok) {
            setItem(response.data)
          }
        }
      }
      setFetching(false)
    }
    const id = parseInt(getCurrentId(props.match.params))
    fetchData(id)
    const interval = setInterval(() => fetchData(id), 1000 * 60)
    return () => clearInterval(interval)
  }, [props.history.location.pathname])

  async function sync (){
    setFetching(true)
    await syncMailFolders(item.id)
    await syncMessages(item.id)
    setFetching(false)
  }

  const syncButton = () => {
    return (
      <Tooltip title='Sincronizar'>
        <IconButton onClick={() => sync()}
          aria-label="Sincronizar">
          {
            fetching
            ? <CircularProgress size={24} className={classes.buttonProgress} />
            : <Sync />
          }
        </IconButton>
      </Tooltip>)
  }

  const onClickNew = async () => {
    const user = JSON.parse(localStorage['user'])
    const response = await messageApi.createMessage({
      from: user.name
    })
    if (response.ok) {
      props.history.push(`/messages-edit?id=${response.data.id}`)
    }
  }


  return (!!item) ?
    (<Grid container direction='row' justify="flex-start" spacing={16}>
        <Grid item lg={12} sm={12}>
          <Paper>
            <Grid container
              justify="space-between"
              alignItems="center"
              className={classes.title}>
              {`Tickets da caixa: ${item.email}`}
              <Grid item>
                {syncButton()}
                <Tooltip title='Novo Email'>
                  <IconButton onClick={onClickNew}
                    aria-label="Novo">
                    <Add />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={12} sm={12}>
          <TicketsList {...props} rerender={fetching} />
        </Grid>
      </Grid>)
    : null
}

export default withStyles(styles)(SharedBoxCard)
