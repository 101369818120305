import React from 'react'
import {
  ListItemText,
  withStyles,
  ListItem,
  Collapse,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core'
// import Menu from '@material-ui/core/Menu'
// import MenuItem from '@material-ui/core/MenuItem'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
// import { mailFolderApi } from '../../services/Api'

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  }
})

class MailFolderItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      openDialog: false,
      anchorEl: null
    }
  }

  handleClose () {
    this.setState({anchorEl: null})
  }

  handleClick () {
    this.setState({
      open: !this.state.open
    })
  }

  // rightClick (event) {
  //   event.preventDefault()
  //   this.setState({anchorEl: event.currentTarget})
  // }

  // async newSubMailFolder () {
  //   this.handleClose()
  //   this.setState({openDialog: true})
  // }


  render() {
    const { classes, displayName, childFolderCount, childfolders, onClick } = this.props
    const { open } = this.state
    return (
      <React.Fragment>
        <ListItem
          button
          onClick={onClick}
          // onContextMenu={(e) => this.rightClick(e)}
          alignItems="flex-start">
          <ListItemText
            primary={displayName} />
          {childFolderCount ?
            <ListItemSecondaryAction
              onClick={() => this.handleClick()}>
              <IconButton edge="end" aria-label="expand">
                {open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </ListItemSecondaryAction>
            : null}
        </ListItem>
        <Collapse in={open} timeout="auto" className={classes.nested}>
          {childfolders.map((child) =>
            <MailFolderItem
              key={child.id}
              classes={classes}
              history={this.props.history}
              onClick={() => this.props.history.push(`/mailfolders/${child.id}`)}
              {...child} />
            )}
        </Collapse>
        {/* <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={() => this.handleClose()}>
          <MenuItem onClick={() => this.newSubMailFolder()}>Criar nova Subpasta</MenuItem>
        </Menu> */}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MailFolderItem)
