import React, {useEffect, useState} from 'react'
import { withStyles, Grid, IconButton, Paper, Tooltip} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Sync from '@material-ui/icons/Sync'
import Add from '@material-ui/icons/Add'
import {
  mailFolderApi,
  messageApi
} from '../../services/Api'
import { MessagesList } from '../Message'

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: 20
  },
  containerList: {
    width: '100%',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingLeft: 16
  }
})

function MailFolderCard({classes, ...props}) {
  const [item, setItem] = useState()
  const [fetching, setFetching] = useState()

  const getCurrentId = (params) => {
    if (params.childId) {
      return params.childId
    }
    return params.id
  }

  useEffect(() => {
    async function fetchData() {
      const id = getCurrentId(props.match.params)
      const response = await mailFolderApi.getOne(id)
      if (response.ok) {
        setItem(response.data)
      }
    }
    fetchData()
  }, [props.history.location.pathname])

  const syncMailFolders = async () => {
    await mailFolderApi.syncAll()
  }

  const syncMessages = async () => {
    await messageApi.syncAll()
  }

  async function sync (){
    setFetching(true)
    await syncMailFolders()
    await syncMessages()
    setFetching(false)
  }

  const syncButton = () => {
    return (
      <Tooltip title='Sincronizar'>
        <IconButton onClick={() => sync()}
        aria-label="Voltar">
          {
            fetching
            ? <CircularProgress size={24} className={classes.buttonProgress} />
            : <Sync />
          }
      </IconButton>
      </Tooltip>)
  }

  const onClickNew = async () => {
    const user = JSON.parse(localStorage['user'])
    const response = await messageApi.createMessage({
      from: user.name
    })
    if (response.ok) {
      props.history.push(`/messages-edit?id=${response.data.id}`)
    }
  }

  return (!!item) ?
    (<Grid container direction='row' justify="flex-start" spacing={16}>
      <Grid item lg={12} sm={12}>
        <Paper>
          <Grid container
            justify="space-between"
            alignItems="center"
            className={classes.title}>
              <Grid item>
                {item.displayName}
              </Grid>
              <Grid item>
                {syncButton()}
                <Tooltip title='Novo Email'>
                  <IconButton onClick={onClickNew}
                    aria-label="Novo">
                    <Add />
                  </IconButton>
                </Tooltip>
              </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item lg={12} sm={12}>
        <MessagesList {...props} rerender={fetching}/>
      </Grid>
    </Grid>)
    : null
}

export default withStyles(styles)(MailFolderCard)
