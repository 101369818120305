import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Divider from '@material-ui/core/Divider'
import MailFolderItem from './MailFolderItem'
import { messageApi } from '../../services/Api'

const styles = theme => ({
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  fab: {
    position: 'fixed',
    right: 4 * theme.spacing.unit,
    bottom: 4 * theme.spacing.unit,
  }
})

class MailFoldersList extends React.Component {
  async onClickNew () {
    const user = JSON.parse(localStorage['user'])
    const response = await messageApi.createMessage({
      from: user.name
    })
    if (response.ok) {
      this.props.history.push(`/messages-edit?id=${response.data.id}`)
    }
  }

  render() {
    const {classes, mailFolders} = this.props
    return (
      <React.Fragment>
        <List className={classes.list}>
          {
            mailFolders.map(element => {
              return (
                <React.Fragment
                  key={element.id}>
                  <MailFolderItem
                    {...element}
                    history={this.props.history}
                    onClick={() => this.props.history.push(`/mailfolders/${element.id}`)} />
                  <Divider component="li" />
                </React.Fragment>
              )
            })
          }
        </List>
        <Fab color="primary" aria-label="Add" className={classes.fab} onClick={() => this.onClickNew()}>
          <AddIcon />
        </Fab>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MailFoldersList)