import React from 'react'
import Grid from '@material-ui/core/Grid'
import { toOption } from '../../lib/Api/Entity'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import Filter from '../../lib/CrudComponents/Filter'
import Autocomplete from '../../lib/Fields/Autocomplete'
import {loopbackFilters} from '../../lib/Api/loopback'
import {ListApi} from '../../lib/Api'
import {List} from '../../lib/CrudComponents'
import {useParentChildrenNavigation, CrudTabs, CrudRoute} from '../../lib/CrudComponents/CrudTabs'
import {
  categoryApi, sharedBoxApi, ticketApi
} from '../../services/Api'
import CategoryForm from './CategoryForm'
import {SubcategoryList} from './Subcategories'
import {SubcategoryForm} from './SubcategoryForm'

const loadSharedBoxes = async (inputValue) => {
  const response = await sharedBoxApi.getList({
    where: {
      email: loopbackFilters.ilikeWithoutSplit(inputValue)
    },
    order: 'email asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('email'))
  }
  return []
}

function CategoryFilter() {
  return (
    <Filter
      formatFilters={{ sharedBoxId: value => value.id }}
      labels={{find: 'Buscar', clear: 'Limpar'}}>
      <Grid spacing={24} container>
        <Grid item sm={6}>
          <Field
            fullWidth
            component={TextField}
            label='Nome'
            name='name'/>
        </Grid>
        <Grid item sm={6}>
          <Field
            label='Shared Box'
            loadOptions={loadSharedBoxes}
            component={Autocomplete}
            name='sharedBoxId' />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function CategoriesList(props) {
  const isFromSharedbox = !!props.match.params.id
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      sharedBox: isFromSharedbox ? null: {
        noOrder: true,
        label: 'Shared box'
      },
      name: {
        label: 'Nome'
      }
    }
  }

  const getPageDecorator = async ({getPage}) => {
    const items = await getPage()
    const promises = items.map(async item => {
      const response = await sharedBoxApi.getOne(item.sharedBoxId)
      if (response.ok) {
        item.sharedBox = response.data.email
        item.sharedBoxId = undefined
        return item
      }
      return item
    })
    return Promise.all(promises)
  }

  const handleDeleteClick = async (category, handleDelete) => {
    const responseTickets = await ticketApi.getList({where: {categoryId: category.id}, limit: 1})
    if (responseTickets.ok) {
      if (responseTickets.data.length === 0) {
        return handleDelete(category)
      }
    }
    return {ok: false, message: 'Existem Tickets com essa categoria!'}
  }

  return (
    <ListApi
      filtersConfig={{
        name: loopbackFilters.ilike
      }}
      getPageDecorator={getPageDecorator}
      api={categoryApi}
      where={isFromSharedbox? {departmentId: +props.match.params.id} : null}>
      {
        ({getPage, handleDelete, getCount}) => (
          <div>
            <CategoryFilter />
            <List
              {...props}
              withPaper
              deleteItem={(item) => handleDeleteClick(item, handleDelete)}
              onClickEdit={(item) => props.history.push(`/categories/${item.id}`)}
              getPage={getPage}
              getCount={getCount}
              listOptions={listOptions} />
          </div>
        )
      }
    </ListApi>
  )
}

export function CategoryTabs(props) {
  const {current, navigate, toEditChild, toNewChild} = useParentChildrenNavigation(props, {mainPath: 'categories', availableTabs: ['subcategory']})
  return (
    <CrudTabs {...props}
      mainPath='categories'
      current={current}
      tabs={[
        {value: '', label: 'Categoria'},
        {value: 'subcategory', label: 'Subcategoria'}
      ]}
      navigate={navigate}>
      <div>
        <CrudRoute
          render={() => <CategoryForm {...props}/>}/>
        <CrudRoute
          name='subcategory'
          render={(props) => <SubcategoryList onClickNew={toNewChild} onClickEdit={toEditChild} {...props} />
          }/>
        <CrudRoute name='subcategory' isForm component={SubcategoryForm}/>
      </div>
    </CrudTabs>
  )
}
