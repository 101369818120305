import React, {useEffect, useState} from 'react'
import { withStyles, Grid } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {
  ticketLogApi
} from '../../services/Api'
import moment from 'moment'
import * as R from 'ramda'

const styles = theme => ({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 16
  }
})

function TicketLogs({classes, ...props}) {
  const [items, setItems] = useState()

  const getCurrentId = (params) => {
    if (params.childId) {
      return params.childId
    }
    return params.id
  }

  useEffect(() => {
    async function fetchData() {
      const ticketId = getCurrentId(props.match.params)
      const filter = {
        where: {ticketId},
        order: 'datetime DESC'
      }
      const response = await ticketLogApi.getList(filter)
      if (response.ok) {
        setItems(response.data)
      }
    }
    fetchData()
  }, [props.history.location.pathname, props.rerender])

  const renderItem = (item) => {
    const renderResponsible = (responsible, attr) => {
      return R.pathOr("Usuário não encontrado", [attr], responsible)
    }

    const user = item.user ? `${item.user} alterou: ` : ''
    let text
    if (item.nextStatusId) {
      const prev = item.prevStatusId ? `de ${item.prevStatus.label} ` : ''
      const next = item.nextStatusId ? `para ${item.nextStatus.label} ` : ''
      text = `${user}status ${prev}${next}`
    } else if (item.nextResponsibleId) {
      const prev = item.prevResponsible ? `de ${renderResponsible(item.prevResponsible, 'email')} ` : ''
      const next = item.nextResponsible ? `para ${renderResponsible(item.nextResponsible, 'email')} ` : ''
      text = `${user}responsável ${prev}${next}`
    } else if (item.nextCategoryId) {
      const prev = item.prevCategoryId ? `de ${item.prevCategory.name} ` : ''
      const next = item.nextCategoryId ? `para ${item.nextCategory.name} ` : ''
      text = `${user}categoria ${prev}${next}`
    } else if (item.nextSubcategoryId) {
      const prev = item.prevSubcategoryId ? `de ${item.prevSubcategory.name} ` : ''
      const next = item.nextSubcategoryId ? `para ${item.nextSubcategory.name} ` : ''
      text = `${user}subcategoria ${prev}${next}`
    } else if (item.note) {
      text = `${user}${item.note}`
    }
    return ( text &&
      <ListItem
        key={item.id}>
        <ListItemText
          primary={text}
          secondary={`${moment(item.datetime).format('DD/MM/YYYY HH:mm:ss')}`}
        />
      </ListItem>
    )
  }

  return (!!items) ?
    (
      <Grid container direction='row' justify="flex-start" spacing={16}>
        <Grid item sm={12} className={classes.title}>
          Histórico
        </Grid>
        <Grid item sm={12}>
          <List dense>
            {items.map(item => renderItem(item))}
          </List>
        </Grid>
      </Grid>
    )
    : null
}

export default withStyles(styles)(TicketLogs)
