import React from 'react'
import { withStyles, Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import PictureAsPdf from '@material-ui/icons/PictureAsPdf'
import PhotoLibrary from '@material-ui/icons/PhotoLibrary'
import VideoLibrary from '@material-ui/icons/VideoLibrary'
import LibraryBooks from '@material-ui/icons/LibraryBooks'
import LibraryMusic from '@material-ui/icons/LibraryMusic'
import AttachFile from '@material-ui/icons/AttachFile'
import {openAttachment, renderAttachment} from '../../utils/download'
import { attachmentApi, getToken } from '../../services/Api'

const styles = theme => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    fontSize: 12,
  },
  root: {
    position: 'relative',
    '&:hover noHover': {
      opacity: 0
    },
    '&:hover hover': {
      opacity: 1
    }
  }
})

const TYPES = ['png', 'jpeg', 'jpg', 'gif', 'bmp', 'pdf']

class AttachmentItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  humanFileSize(size) {
    const i = Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  renderIcon (contentType) {
    if (contentType.includes('image')) {
      return (<PhotoLibrary />)
    } else if (contentType.includes('pdf')) {
      return (<PictureAsPdf />)
    } else if (contentType.includes('text')) {
      return (<LibraryBooks />)
    } else if (contentType.includes('video')) {
      return (<VideoLibrary />)
    } else if (contentType.includes('audio')) {
      return (<LibraryMusic />)
    } else {
      return (<AttachFile />)
    }
  }

  async renderOrOpen (name, contentType, contentBytes) {
    // contornando anexos do Outlook desktop
    const { setMessage, attachmentId, messageId }  = this.props
    if (contentType === 'message/rfc822') {
      setMessage('Preparando download...')
      const anchor = document.createElement("a")
      document.body.appendChild(anchor)
      const file = attachmentApi.getAttachmentValue(messageId, attachmentId)
      const [header, token] = getToken()

      const headers = new Headers()
      headers.append(header, token)

      fetch(file, { headers })
        .then(response => response.blob())
        .then(blobby => {
          let objectUrl = window.URL.createObjectURL(blobby)

          anchor.href = objectUrl
          anchor.download = `${name}.eml`
          anchor.click()

          window.URL.revokeObjectURL(objectUrl)
          setMessage('')
        })
        .catch(e => {
          console.log(e)
          setMessage('')
        })
    } else {
      const type = name.split('.').slice(-1)[0]
      if (TYPES.includes(type)) {
        renderAttachment(name, contentType, contentBytes)
      } else {
        openAttachment(name, contentType, contentBytes)
      }
    }
  }

  render() {
    const { classes, name, contentType, contentBytes, size } = this.props
    return ( contentType &&
      <Grid item>
        <IconButton
          size="small"
          aria-label="attachment"
          className={classes.button}
          onClick={() => this.renderOrOpen(name, contentType, contentBytes)}>
          {this.renderIcon(contentType)}
          { `${name} (${this.humanFileSize(size)})`}
        </IconButton>
      </Grid>
    )
  }
}

export default withStyles(styles)(AttachmentItem)
