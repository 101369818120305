import React from 'react'
import {
  ListItemText,
  withStyles,
  ListItem,
  Collapse,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import classNames from 'classnames'
import {compose} from 'recompose'
import {withRouter} from 'react-router-dom'
// import Menu from '@material-ui/core/Menu'
// import MenuItem from '@material-ui/core/MenuItem'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
// import { mailFolderApi } from '../../services/Api'

const MATCHBAR = /.\/./

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing.unit,
  },
  menuColor : {
    color: theme.palette.grey[500]
  },
  menuColorActive : {
    color: theme.palette.common.white
  },
  active:{
    borderLeft: `solid 3px ${theme.palette.primary.main}`,
    borderImageSlice: 1
  }
})

class MenuItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      openDialog: false,
      anchorEl: null
    }
  }

  handleClose () {
    this.setState({anchorEl: null})
  }

  handleClick () {
    this.setState({
      open: !this.state.open
    })
  }

  // rightClick (event) {
  //   event.preventDefault()
  //   this.setState({anchorEl: event.currentTarget})
  // }

  // async newSubMailFolder () {
  //   this.handleClose()
  //   this.setState({openDialog: true})
  // }

  handleItemClick (group, pathname) {
    if (group) {
      this.handleClick()
    } else {
      this.props.history.push(pathname)
    }
  }

  render() {
    const { classes, location, label, exact=false, icon, items, pathname, group, colapseAll } = this.props
    let active = false
    if (MATCHBAR.test(location.pathname) && !exact) {
      if (pathname) {
        if (pathname.split('/')[1] === location.pathname.split('/')[1]) {
          active = true
          if (pathname.includes('sharedBoxes')) {
            active = pathname.split('?')[0] === location.pathname.split('?')[0]
          }
        }
      }
    } else {
      active = location.pathname === pathname
    }
    const open= this.state.open && colapseAll
    return (
      <div
        key={pathname}
        className={this.props.classes.menuColor}>
        <ListItem
          button
          classes={{root: active && this.props.classes.active}}
          onClick={() => this.handleItemClick(group, pathname)}
          // onContextMenu={(e) => this.rightClick(e)}
          alignItems="flex-start">
          {icon && (
            <ListItemIcon
              classes={{root: active ? this.props.classes.menuColorActive : this.props.classes.menuColor}}>
              <this.props.icon />
            </ListItemIcon>
          )}
          <ListItemText
            primary={label}
            classes={{primary: classNames(active ? this.props.classes.menuColorActive : this.props.classes.menuColor)}}/>
          {items && items.length > 0 ?
            <ListItemSecondaryAction
              onClick={() => this.handleClick()}>
              <IconButton edge="end" aria-label="expand" color='inherit'>
                {open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </ListItemSecondaryAction>
            : null}
        </ListItem>
        <Collapse in={open} timeout="auto" className={classes.nested}>
          {items && items.map((child) =>
            <MenuItem
              key={child.pathname}
              classes={classes}
              location={location}
              colapseAll={colapseAll}
              history={this.props.history}
              onClick={() => this.props.history.push(`/mailfolders/${child.id}`)}
              {...child} />
            )}
        </Collapse>
        {/* <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={() => this.handleClose()}>
          <MenuItem onClick={() => this.newSubMailFolder()}>Criar nova Subpasta</MenuItem>
        </Menu> */}
      </div>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(MenuItem)
