import React from 'react'
import { CirclePicker } from 'react-color'
import FormHelperText from '@material-ui/core/FormHelperText'

export default function ColorPicker({input, meta, ...props}) {
  return (
    <div>
      <CirclePicker
        id='color-picker'
        color={input.value.hex}
        onChangeComplete={input.onChange}
        triangle='hide'/>
      <FormHelperText
        id="color-picker"
        error
        hidden={!meta.touched}>
        {meta.error}
      </FormHelperText>
    </div>
  )
}
