import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const styles = theme => ({
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing.unit * 4
  },
  root: {
    width: '100%'
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center'
  },
  ball: {
    width: 20,
    height: 20,
    marginRight: 5,
    borderRadius: 50
  }
})

const StatusSelect = ({input, label, meta, options, classes, ...props}) => {
  return (
  <FormControl className={classes.root}>
    <InputLabel
      htmlFor="age-simple"
      error={meta.touched && !!meta.error}>
      {label}
    </InputLabel>
    <Select
      {...props}
      error={meta.error && meta.touched}
      value={input.value}
      onChange={input.onChange}
      onBlur={input.onBlur}>
      {
        options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <div className={classes.menuItem}>
              <div
                className={classes.ball}
                style={{backgroundColor: option.color}}
                ></div>
              {option.label}
            </div>
          </MenuItem>
        ))
      }
    </Select>
    <FormHelperText error>{meta.touched && meta.error}</FormHelperText>
  </FormControl>
)}

export default withStyles(styles)(StatusSelect)