import React, {useEffect, useState} from 'react'
import { Paper, withStyles, Grid, IconButton, Tooltip } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import Sync from '@material-ui/icons/Sync'
import {
  mailFolderApi,
  userMailApi,
  messageApi
} from '../../services/Api'
import MailFoldersList from '../MailFolder/MailFolders'

const styles = theme => ({
  root: {
    padding: 16,
    marginBottom: 20
  },
  containerList: {
    width: '100%',
    overflowX: 'auto',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 16,
    paddingLeft: 16
  }
})

function UserMailCard({classes, ...props}) {
  const [item, setItem] = useState()
  const [mailFolders, setMailFolders] = useState()
  const [fetching, setFetching] = useState()

  const getCurrentId = (params) => {
    if (params.childId) {
      return params.childId
    }
    return params.id
  }

  const fetchMailFolders = async (userMailId) => {
    const where = {
      and: [
        {userMailId},
        {depth: 0}
      ]
    }
    const resposeMailFolders = await mailFolderApi.getList({where})
    if (resposeMailFolders.ok) {
      setMailFolders(resposeMailFolders.data)
    } else {
      setMailFolders([])
    }
  }

  const syncMailFolders = async (userMailId) => {
    await mailFolderApi.syncAll()
    await fetchMailFolders(userMailId)
  }

  const syncMessages = async () => {
    await messageApi.syncAll()
  }

  useEffect(() => {
    async function fetchData() {
      const id = getCurrentId(props.match.params)
      const response = await userMailApi.getOne(id)
      if (response.ok) {
        await fetchMailFolders(response.data.id)
        setItem(response.data)
      }
    }
    fetchData()
  }, [props.history.location.pathname])

  async function sync (){
    setFetching(true)
    await syncMailFolders(item.id)
    await syncMessages(item.id)
    setFetching(false)
  }

  const syncButton = () => {
    return (
      <Tooltip title='Sincronizar'>
        <IconButton onClick={() => sync()}
        aria-label="Sincronizar">
          {
            fetching
            ? <CircularProgress size={24} className={classes.buttonProgress} />
            : <Sync />
          }
      </IconButton>
      </Tooltip>)
  }

  return (!!item) ?
    (<Paper className={classes.root}>
      <Grid container direction='row' justify="flex-start" spacing={16}>
        <Grid container justify="space-between" alignItems="center" className={classes.title}>
          {item.email}
          {syncButton()}
        </Grid>
        <Grid className={classes.containerList}>
          <List className={classes.list}>
            <MailFoldersList
              mailFolders={mailFolders}
              history={props.history}/>
          </List>
        </Grid>
      </Grid>
    </Paper>)
    : null
}

export default withStyles(styles)(UserMailCard)
