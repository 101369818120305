import React from 'react'
import { CrudForm } from '../../lib/CrudComponents'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import Checkbox from '../../lib/Common/Checkbox'
import { Field } from 'react-final-form'
import { FormApi } from '../../lib/Api'
import { statusApi} from '../../services/Api'
import ColorPicker from '../../lib/Fields/ColorPicker'

export function StatusForm(props) {
  const getItemDecorator = async ({ getItem, filter }) => {
    const item = await getItem(filter)
    if (item) {
      return {
        ...item,
        color: {hex: item.color}
      }
    }
    return {}
  }
  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        sharedBoxId: Number(props.match.params.id),
        color: data.color.hex,
        label: data.label
      }
    })
    return result
  }

  const validate = values => ({
    label: (values.label && values.label.trim()) ? undefined : 'Obrigatório',
    color: values.color ? undefined : 'Obrigatório'
  })

  return (
    <FormApi
      {...props}
      getItemDecorator={getItemDecorator}
      submitDecorator={submitDecorator}
      api={statusApi} >
      {
        ({ handleSubmit, getItem }) => (
          <CrudForm
            validate={validate}
            history={props.history}
            getItem={getItem}
            onSubmit={handleSubmit}>
            <Grid direction='row' container spacing={16}>
              <Grid item sm={4}>
                <Field
                  fullWidth
                  component={TextField}
                  label='Label'
                  name='label' />
              </Grid>
              <Grid item sm={4}>
                <Field
                  fullWidth
                  component={ColorPicker}
                  label='Cor'
                  name='color' />
              </Grid>
              <Grid item sm={2}>
                <Field
                  type='checkbox'
                  label='Final'
                  component={Checkbox}
                  name='isFinal' />
              </Grid>
            </Grid>
          </CrudForm>
        )
      }
    </FormApi>
  )
}
