import React from 'react'
import {List} from '../../lib/CrudComponents'
import {ListApi} from '../../lib/Api'
import {subcategoryApi, ticketApi} from '../../services/Api'

export function SubcategoryList({classes, ...props}) {
  const isFromCategory = !!props.match.params.id

  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      category: isFromCategory ? null: {
        label: 'Categoria'
      },
      name:{
        label: 'Subcategoria'
      }
    }
  }
  const getPageDecorator = async ({getPage}) => {
    const items = await getPage()
    if (isFromCategory) return items
    return
  }

  const handleDeleteClick = async (subcategory, handleDelete) => {
    const responseTickets = await ticketApi.getList({where: {subcategoryId: subcategory.id}, limit: 1})
    if (responseTickets.ok) {
      if (responseTickets.data.length === 0) {
        return handleDelete(subcategory)
      }
    }
    return {ok: false, message: 'Existem Tickets com essa subcategoria!'}
  }

  return (
    <ListApi
      api={subcategoryApi}
      getPageDecorator={getPageDecorator}
      where={isFromCategory? {categoryId: +props.match.params.id} : null}>
      {
        ({getPage, getCount, handleDelete}) => (
          <List
            {...props}
            withPaper={props.withPaper}
            deleteItem={(item) => handleDeleteClick(item, handleDelete)}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions} />
        )
      }
    </ListApi>
  )
}
