import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { MuiThemeProvider} from '@material-ui/core/styles'
import theme from './Theme'
import RecoverPassword from './modules/Login/RecoverPassword'
import Login from './modules/Login/Login'
import Home from './modules/Home'
import {setToken, authenticated} from './services/Api'
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import {loginRoutes, PrivateRoute} from './lib/Login'
import AuthProvider from './lib/Login/Auth'

const verifyUser = () => {
  const stringUser = localStorage['user']
  if (stringUser) {
    const user = JSON.parse(stringUser)
    setToken(user.token)
    authenticated()
      .then((response) => {
        if (response.problem) {
          delete localStorage.user
          document.location.reload()
        }
      })
      .catch((e) => console.error(e))
  }
}

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthProvider withUser={() => verifyUser()}>
            <Router>
              <Switch>
                <Route path={loginRoutes.login} component={Login} />
                <Route path={loginRoutes.recoverPassword} component={RecoverPassword}/>
                <PrivateRoute path='/' component={Home}/>
              </Switch>
            </Router>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
