import * as R from 'ramda'

export const apiCrudFactory = ({api, defaultFilter}) => (path, {transformers, urls} = {}) => {
  return {
    getList     : (filter = {}) => api.get(path, {'filter': R.mergeDeepWith(R.join, defaultFilter, filter)}),
    getOne      : async (id, filter) => {
      const response = await api.get(`${path}/${id}`, {filter: filter})
      if (transformers && transformers.getOne) {
        const newData = transformers.getOne(response.data)
        response.data = newData
      }
      return response
    },
    getCount: (filter ={}) => api.get(`${path}/count`, R.mergeDeepWith(R.join, defaultFilter, filter)),
    update      : (id, data)   => api.patch(`${path}/${id}`, data),
    create      : data   => {
      const endpoint = urls && urls.post ? urls.post : path
      return api.post(endpoint, data)
    },
    remove      : id     => api.patch(`${path}/${id}`, {removed: true}),
    deleteF     : id     => api.delete(`${path}/${id}`),
    undoRemove  : id     => api.patch(`${path}/${id}`, {removed: false})
  }
}
