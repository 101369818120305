import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  card: {
    width: 196,
    alignSelf: 'flex-start',
    margin: '8px',
    position: 'relative'
  },
  remove: {
    background: 'rgba(black, .54)',
    color: 'black',
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: '2',
  }
})
class UploadedFileCard extends PureComponent {
  static propTypes = {
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
    }).isRequired,
    onRemoveClick: PropTypes.func.isRequired
  }

  removeCard = () => {
    this.props.onRemoveClick(this.props.file);
  }

  humanFileSize = (size) => {
    const i = Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  render() {
    const { classes } = this.props
    const { name, size, type } = this.props.file

    return (
      <Card className={classes.card}>
        <Tooltip title='Remover'>
          <IconButton
            size="small"
            onClick={this.removeCard}
            aria-label="Remover"
            className={classes.remove}>
            <Close />
          </IconButton>
        </Tooltip>
        <CardActionArea>
          <CardContent>
            <Typography variant="subtitle2" gutterBottom>
              {name}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {type} - {this.humanFileSize(size)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

export default withStyles(styles)(UploadedFileCard)