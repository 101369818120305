import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'


class MessageItem extends React.Component {
  render() {
    const { from, subject, isRead, created, onClick } = this.props
    return (
      <div onClick={onClick}>
        <ListItem
          button
          divider
          selected={!isRead}
          alignItems='flex-start'>
            <ListItemText
              disableTypography
              primary={from}
              secondary={
                <Typography
                  component='span'
                  variant='body2'
                  color='textPrimary'>
                  {subject}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <ListItemText
                disableTypography
                primary={moment(created).format('DD/MM/YYYY')}
                secondary={
                  <Typography
                    component='span'
                    variant='body2'
                    color='textPrimary'>
                    {moment(created).format('HH:mm:ss')}
                  </Typography>
                }
              />
            </ListItemSecondaryAction>
        </ListItem>
      </div>
    )
  }
}

export default (MessageItem)
