export const renderAttachment = (name, contentType, contentBytes) => {
  contentType = contentType || ''
  const sliceSize = 512
  const byteCharacters = window.atob(contentBytes)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, {
    type: contentType
  })
  const fileUrl = window.URL.createObjectURL(blob)
  window.open(fileUrl, name)
}

export const openAttachment = (name, contentType, contentBytes) => {
  contentType = contentType || ''
  const sliceSize = 512
  const byteCharacters = window.atob(contentBytes)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, {
    type: contentType
  })
  const fileUrl = window.URL.createObjectURL(blob)
  // window.open(fileUrl, name)
  const fileLink = document.createElement('a')
  fileLink.href = fileUrl
  fileLink.target = '_blank'
  fileLink.download = name
  fileLink.click()
  fileLink.remove()
}