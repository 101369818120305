import React from 'react'
import { CrudForm } from '../../lib/CrudComponents'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { FormApi } from '../../lib/Api'
import { subcategoryApi } from '../../services/Api'

export function SubcategoryForm(props) {
  const getItemDecorator = async ({ getItem, filter }) => {
    return await getItem(filter)
  }
  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        categoryId: Number(props.match.params.id),
      }
    })
    return result
  }

  const validate = values => ({
    name: (values.name && values.name.trim()) ? undefined : 'Obrigatório'
  })

  return (
    <FormApi
      {...props}
      getItemDecorator={getItemDecorator}
      submitDecorator={submitDecorator}
      api={subcategoryApi} >
      {
        ({ handleSubmit, getItem }) => (
          <CrudForm
            validate={validate}
            history={props.history}
            getItem={getItem}
            onSubmit={handleSubmit}>
            <Grid container spacing={16}>
              <Grid item sm>
                <Field
                  fullWidth
                  component={TextField}
                  label='Nome'
                  name='name' />
              </Grid>
            </Grid>
          </CrudForm>
        )
      }
    </FormApi>
  )
}