import React from 'react'
import {ListApi} from '../../lib/Api'
import {loopbackFilters} from '../../lib/Api/loopback'
import Filter from '../../lib/CrudComponents/Filter'
import Grid from '@material-ui/core/Grid'
import {TextField} from 'final-form-material-ui'
import {Field} from 'react-final-form'
import {
  messageApi
} from '../../services/Api'
import ListMessages from './ListMessages'

function MessageFilter(props) {
  return (
    <Filter labels={{find: 'Buscar', clear: 'Limpar'}}>
      <Grid spacing={24} container>
        <Grid item sm={4}>
          <Field
            fullWidth
            component={TextField}
            label='De'
            name='from'/>
        </Grid>
        <Grid item sm={8}>
          <Field
            fullWidth
            component={TextField}
            label='Assunto'
            name='subject'/>
        </Grid>
      </Grid>
    </Filter>
  )
}

export function MessagesList(props) {
  const listOptions = {
    defaultOrder: 'createdDateTime DESC',
    fields: {
      id: {
        label: 'Id'
      },
      from: {
        label: 'De'
      },
      subject: {
        label: 'Assunto'
      }
    }
  }

  const getPageDecorator = async ({getPage}) => {
    const items = await getPage()
    return items
  }

  return (
    <ListApi
      filtersConfig={{
        subject: loopbackFilters.ilike,
        from: loopbackFilters.ilike
      }}
      getPageDecorator={getPageDecorator}
      api={messageApi}>
        {
          ({getPage, getCount}) => (
            <div>
              <MessageFilter />
              <ListMessages
                {...props}
                withPaper
                getPage={getPage}
                getCount={getCount}
                listOptions={listOptions} />
            </div>
          )
        }
    </ListApi>
  )
}
