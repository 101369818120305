import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {List} from '../../lib/CrudComponents'
import {ListApi} from '../../lib/Api'
import {statusApi, ticketApi} from '../../services/Api'

const styles = theme => ({
  ball: {
    width: 20,
    height: 20,
    marginRight: 5,
    borderRadius: 50
  }
})

function StatusList({classes, ...props}) {
  const isFromSharedBox = !!props.match.params.id

  const formatColor = (color) => {
    return (
      <div className={classes.ball}
        style={{backgroundColor: color}}></div>)
  }

  const listOptions = {
    defaultOrder: 'id asc',
    fields: {
      sharedBox: isFromSharedBox ? null: {
        label: 'SharedBox'
      },
      label:{
        label: 'Status'
      },
      color: {
        label: 'Cor',
        format: color => formatColor(color)
      }
    }
  }
  const getPageDecorator = async ({getPage}) => {
    const items = await getPage()
    if (isFromSharedBox) return items
    return
  }

  const handleDeleteClick = async (status, handleDelete) => {
    const responseTickets = await ticketApi.getList({where: {statusId: status.id}, limit: 1})
    if (responseTickets.ok) {
      if (!responseTickets.data.length) {
        return handleDelete(status)
      }
    }
    return {ok: false, message: 'Existem Tickets com esse status!'}
  }

  return (
    <ListApi
      api={statusApi}
      getPageDecorator={getPageDecorator}
      where={isFromSharedBox? {sharedBoxId: +props.match.params.id} : null}>
      {
        ({getPage, getCount, handleDelete}) => (
          <List
            {...props}
            withPaper={props.withPaper}
            deleteItem={(item) => handleDeleteClick(item, handleDelete)}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions} />
        )
      }
    </ListApi>
  )
}

export default withStyles(styles)(StatusList)
