const charsToEscape = ['(',')', '.', '+']
const escapeChars = text => {
  let newText = text + ''
  charsToEscape.forEach(char => newText = newText.replace(char, `\\${char}`))
  return newText
}

export const loopbackFilters = {
  regexp: value => ({regexp: `/${escapeChars(value)}/i`}),
  like: value => ({like: `%${value}%`}),
  ilike: value => ({ ilike: `%${value.split('').join('%')}%` }),
  ilikeWithoutSplit: value => ({ ilike: `%${value}%` })
}
