import React from 'react'
import {RecoverPassword} from '../../lib/Login'
import { resetAccessToken } from '../../services/Api'

export default function CustomRecoverPassword({history}) {

  const handleSubmit = async ({username}) => {
    const response = await resetAccessToken({
      email: username
    })
    if (response.ok) {
      return Promise.resolve({ok: true, message: 'Credenciais resetadas, faça novo login para obter autorização'})
    }
    return {ok: false, message: 'Email inválido'}
  }

  return (
    <RecoverPassword
      history={history}
      usernameLabel='Email'
      requiredLabel='Obrigatório'
      backLabel='Voltar'
      submitLabel='Resetar'
      onBackClick={() => history.goBack()}
      onSubmit={handleSubmit}
      />
  )
}

