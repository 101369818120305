import { create } from 'apisauce'
import { apiCrudFactory } from '../lib/Api/CrudApi'
import config from '../Config'

export const baseURL = config.API_URL

const AUTH_HEADER = 'Authorization'
const api = create({
  baseURL
})

const defaultFilter = {
  where: {
    removed: false
  }
}

const createCrud = apiCrudFactory({ api, defaultFilter })
export const customLogin = credentials => api.post('UserMails/customLogin', credentials)
export const logout = () => api.post('UserMails/logout')
export const authenticated = () => api.post('UserMails/authenticated')
export const resetAccessToken = email => api.post('UserMails/resetAccessToken', email)
export const messageApi = {
  ...createCrud('Messages'),
  createReply: (id, body) => api.post(`Messages/${id}/createReply`, body),
  createReplyAll: (id, body) => api.post(`Messages/${id}/createReplyAll`, body),
  createForward: (id) => api.post(`Messages/${id}/createForward`),
  createMessage: (body) => api.post(`Messages/createMessage`, body),
  updateMessage: (id, body) => api.patch(`Messages/${id}/updateMessage`, body),
  deleteMessage: (id) => api.delete(`Messages/${id}/deleteMessage`),
  getMessage: (id) => api.get(`Messages/${id}/getMessage`),
  getMessageValue: (id) => (`${baseURL}/Messages/${id}/getMessageValue`),
  moveMessage: (id, mailFolderId) => api.post(`Messages/${id}/moveMessage/${mailFolderId}`),
  send: (id) => api.post(`Messages/${id}/send`),
  syncAll: () => api.get(`Messages/syncAll`)
}
export const userMailApi = {
  ...createCrud('UserMails'),
  getSharedBoxes: (id) => api.get(`UserMails/${id}/sharedBoxes`),
  setSignature: (body) => api.post(`UserMails/signature`, body),
  getSignature: () => api.get(`UserMails/signature`)
}
export const ticketApi = {
  ...createCrud('Tickets'),
  counts: (sharedBoxId) => api.get(`Tickets/${sharedBoxId}/counts`)
}
export const ticketLogApi = createCrud('TicketLogs')
export const statusApi = createCrud('Statuses')
export const departmentApi = createCrud('Departments')
export const categoryApi = {
  ...createCrud('Categories'),
  listSubcategories: (id) => api.get(`Categories/${id}/subcategories`)
}
export const subcategoryApi = createCrud('Subcategories')
export const mailFolderApi = {
  ...createCrud('MailFolders'),
  syncAll: () => api.get('MailFolders/syncAll'),
  createSubMailFolder: (id, body) => api.post(`MailFolders/${id}/createSubMailFolder`, body)
}
export const sharedBoxApi = {
  ...createCrud('SharedBoxes'),
  syncAllMailFolders: (id) => api.get(`/SharedBoxes/${id}/syncAllMailFolders`),
  syncAllMessages: (id) => api.get(`/SharedBoxes/${id}/syncAllMessages`),
  addRelation: (body) => api.post(`/SharedBoxes/addRelation`, body),
  listUserMails: (id, filter) => api.get(`SharedBoxes/${id}/userMails`, filter),
  listCategories: (id, filter) => api.get(`SharedBoxes/${id}/categories`, filter),
  getUrlStream: () => (`${baseURL}/SharedBoxes/change-stream?_format=event-stream`)
}
export const attachmentApi = {
  getAttachments: (messageId) => api.get(`Attachments/${messageId}/fetchAttachments`),
  getAttachmentValue: (messageId, attachmentId) => (`${baseURL}/Attachments/${messageId}/getAttachmentValue/${attachmentId}`),
  createAttachment: (messageId, body) => api.post(`Attachments/${messageId}/createAttachment`, body),
  deleteAttachment: (messageId, attachmentId) => api.delete(`Attachments/${messageId}/deleteAttachment/${attachmentId}`)
}
export const contactsApi = {
  list: (filter) => api.get(`Contacts/list`, filter)
}
export const setToken = token => api.setHeader(AUTH_HEADER, token)
export const getToken = () => {
  const user = JSON.parse(localStorage['user'])
  return ([AUTH_HEADER, user.token])
}
