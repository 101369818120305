import React from 'react'
import Paper from '@material-ui/core/Paper'
import {withStyles} from '@material-ui/core/styles'
import * as R from 'ramda'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import MessageItem from './MessageItem'
import Pagination from '../../lib/CrudComponents/Pagination'
import {getRowsPerPage} from '../../utils/configUser'

const styles = theme => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  }
})

class ListMessages extends React.Component {
  state = {
    fields: [],
    list: [],
    loading: true
  }

  constructor(props) {
    super(props)
    const fields = this.createFields()
    this.state = { fields }
  }

  createFields = () => {
    const {fields} = this.props.listOptions
    return R.keys(fields).filter(s => !!fields[s]).map(source => {
      const field =  fields[source]
      const {label = source, noOrder, type, format, getOne, key} = field
      return {
        noOrder,
        key,
        getOne,
        format,
        label,
        source,
        type
      }
    })
  }

  urlFilterToObject = filter => {
    if (!filter) return {}
    return filter.split(',')
      .reduce((obj, keyValue) => {
        const [key, value] = keyValue.split('=')
        return {...obj, [key]: value}
      }, {})
  }

  getCurrentId = (params) => {
    if (params.childId) {
      return params.childId
    }
    return params.id
  }

  updatePage = async () => {
    const {getPage, getCount, location, listOptions} = this.props
    const params = new URLSearchParams(location.search)
    const filter = this.urlFilterToObject(params.get('filters'))
    const folderId = this.getCurrentId(this.props.match.params)
    filter['folderId'] = folderId
    if (getPage) {
      this.setState({loading: true})
      const page = +params.get('page') || 0
      const rowsPerPage = +params.get('rows') || getRowsPerPage()
      const order = params.get('order') || listOptions.defaultOrder
      const list = await getPage({page, rowsPerPage, filter, order})
      this.setState({ list, loading: false }) }
    if (getCount) {
      const count = await getCount({filter})
      this.setState({ count })
    }
  }

  componentDidMount() {
    this.updatePage()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.rerender) {
      this.updatePage()
      return
    }
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.updatePage()
      return
    }
    if (this.props.location.search === prevProps.location.search)  return
    this.updatePage()
  }

  renderList = () => {
    const {classes, withPaper, getCount, labelRowsPerPage='Linhas por página', ofLabel='de', history, location} = this.props
    const {list = [], loading} = this.state
    const {count=0} = this.state
    const Container = withPaper ? Paper : (props => <div {...props}/>)
    return (
      <Container padding={0}>
        {loading ? <LinearProgress/>: null}
        <Paper className={classes.paper}>
          <List className={classes.list}>
            {
              list.map(element => {
                return (
                  <MessageItem
                   key={element.id}
                   subject={element.subject}
                   type={element.type}
                   from={element.from}
                   isRead={element.isRead}
                   created={element.createdDateTime}
                   onClick={() => this.props.history.push(`/messages/${element.id}`)}/>)
              })
            }
          </List>
          {
            getCount && (
              <Pagination
                count={count}
                history={history}
                location={location}
                ofLabel={ofLabel}
                labelRowsPerPage={labelRowsPerPage} />
            )
          }
        </Paper>
      </Container>
    )
  }

  render() {
    return this.renderList()
  }
}

export default withStyles(styles)(ListMessages)
