import React from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


export default function SelectField({input, hideEmpty, disabled, label, meta, options, onChange}) {
  function handleChange (event) {
    input.onChange(event)
    onChange && onChange(event)
  }

  return (
    <FormControl style={{width: '100%'}}>
      <InputLabel htmlFor="age-simple" error={meta.touched && !!meta.error}>{label}</InputLabel>
      <Select
        disabled={disabled}
        error={meta.error && meta.touched}
        value={input.value}
        onChange={handleChange}
        onBlur={input.onBlur}>
        {
          hideEmpty ? null : (
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
          )
        }
        {
          options.map(option => (
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
          ))
        }
      </Select>
      <FormHelperText error>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  )
}
