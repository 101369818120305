import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useDrag } from 'react-dnd'
import moment from 'moment'
import { withStyles, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  row: {
    fontSize: 12,
    minHeight: 32,
    verticalAlign: 'middle',
    padding: '12px 0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)'
    }
  },
  highlight: {
    fontWeight: 'bold'
  },
  status: {
    margin: '0 12px',
    fontSize: 10,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'white',
    borderRadius: 50
  }
})

function TicketItem({ classes, ...props }) {
  const {
    id,
    status,
    name,
    responsible,
    senderName,
    senderMail,
    category,
    subcategory,
    lastUpdate,
    size,
    highlight,
    onClickView
  } = props
  const statusId = status ? status.id : null
  const [, drag] = useDrag({
    item: { id, statusId, type: 'row' }
  })

  const sender = senderName ? senderName : (senderMail ? senderMail : '')
  const responsiblemail = responsible ? responsible.email.split('@')[0] : ''
  const categorySubcategory = `${category ? category.name : ''} ${subcategory ? ` - ${subcategory.name}` : ''}`

  return (
    <div ref={drag} onClick={onClickView}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.row}>
        <Grid item sm={1}>
          {status && <div className={classes.status} style={{ backgroundColor: status.color }}>
            {status.label}
          </div>}
        </Grid>
        <Grid item sm={1} className={highlight ? classes.highlight : null}>
          <Tooltip title={sender} placement="bottom-end" enterDelay={1000}>
            <Typography variant="inherit" gutterBottom noWrap>
              {sender}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item sm={5} className={highlight ? classes.highlight : null}>
          <Tooltip title={name} placement="bottom-end" enterDelay={1000}>
            <Typography variant="inherit" gutterBottom noWrap>
              {name}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item sm={2} className={highlight ? classes.highlight : null}>
          <Tooltip title={responsiblemail} placement="bottom-end" enterDelay={1000}>
            <Typography variant="inherit" gutterBottom noWrap>
              {responsiblemail}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item sm={2} className={highlight ? classes.highlight : null}>
          <Tooltip title={categorySubcategory} placement="bottom-end" enterDelay={1000}>
            <Typography variant="inherit" gutterBottom noWrap>
              {categorySubcategory}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item sm={1} className={highlight ? classes.highlight : null}>
          {moment(lastUpdate).format('DD/MM/YY HH:mm')}⠀ ⠀
          {size}
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(TicketItem)