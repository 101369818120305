import React from 'react'
import Grid from '@material-ui/core/Grid'
import { toOption } from '../../lib/Api/Entity'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import Filter from '../../lib/CrudComponents/Filter'
import Autocomplete from '../../lib/Fields/Autocomplete'
import {loopbackFilters} from '../../lib/Api/loopback'
import {ListApi} from '../../lib/Api'
import {List} from '../../lib/CrudComponents'
import {useParentChildrenNavigation, CrudTabs, CrudRoute} from '../../lib/CrudComponents/CrudTabs'
import {
  sharedBoxApi,
  departmentApi
} from '../../services/Api'
import StatusList from './Statuses'
import {StatusForm} from './StatusForm'
import SharedBoxForm from './SharedBoxForm'
import {CategoriesList} from '../Category'

const loadDepartments = async (inputValue) => {
  const response = await departmentApi.getList({
    where: {
      name: loopbackFilters.ilikeWithoutSplit(inputValue)
    },
    order: 'name asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('name'))
  }
  return []
}

function SharedBoxFilter() {
  return (
    <Filter
      formatFilters={{ departmentId: value => value.id }}
      labels={{find: 'Buscar', clear: 'Limpar'}}>
      <Grid spacing={24} container>
        <Grid item sm={6}>
          <Field
            fullWidth
            component={TextField}
            label='Email'
            name='email'/>
        </Grid>
        <Grid item sm={6}>
          <Field
            label='Departamento'
            loadOptions={loadDepartments}
            component={Autocomplete}
            name='departmentId' />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function SharedBoxesList(props) {
  const isFromDepartment = !!props.match.params.id
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      department: isFromDepartment ? null: {
        noOrder: true,
        label: 'Departamento'
      },
      email: {
        label: 'Email'
      }
    }
  }

  const getPageDecorator = async ({getPage}) => {
    const items = await getPage()
    const promises = items.map(async item => {
      const response = await departmentApi.getOne(item.departmentId)
      if (response.ok) {
        item.department = response.data.name
        item.departmentId = undefined
        return item
      }
      return item
    })
    return Promise.all(promises)
  }

  return (
    <ListApi
      filtersConfig={{
        email: loopbackFilters.ilike
      }}
      getPageDecorator={getPageDecorator}
      api={sharedBoxApi}
      where={isFromDepartment? {departmentId: +props.match.params.id} : null}>
      {
        ({getPage, handleDelete, getCount}) => (
          <div>
            <SharedBoxFilter />
            <List
              {...props}
              withPaper
              onClickEdit={(item) => props.history.push(`/sharedBoxesAdmin/${item.id}`)}
              getPage={getPage}
              getCount={getCount}
              listOptions={listOptions} />
          </div>
        )
      }
    </ListApi>
  )
}

export function SharedBoxTabs(props) {
  const {current, navigate, toEditChild, toNewChild} = useParentChildrenNavigation(props, {mainPath: 'sharedBoxesAdmin', availableTabs: ['status', 'category']})
  return (
    <CrudTabs {...props}
      mainPath='sharedBoxesAdmin'
      current={current}
      tabs={[
        {value: '', label: 'SharedBox'},
        {value: 'status', label: 'Status'},
        {value: 'category', label: 'Categorias'},
      ]}
      navigate={navigate}>
      <div>
        <CrudRoute
          render={() => <SharedBoxForm {...props}/>}/>
        <CrudRoute
          name='status'
          render={(props) => <StatusList onClickNew={toNewChild} onClickEdit={toEditChild} {...props} />
          }/>
        <CrudRoute name='status' isForm component={StatusForm}/>
        <CrudRoute
          name='category'
          render={(props) => <CategoriesList {...props} onClickNew={() => props.history.push(`/categories/new?sharedBoxId=${props.match.params.id}`)} />} />
      </div>
    </CrudTabs>
  )
}
