import React, {useEffect, useState, useRef} from 'react'
import ReactToPrint from 'react-to-print'
import Snackbar from '../../lib/Common/Snackbar'
import { Paper, withStyles, Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Reply from '@material-ui/icons/Reply'
import ReplyAll from '@material-ui/icons/ReplyAll'
import Forward from '@material-ui/icons/Forward'
import MoreVert from '@material-ui/icons/MoreVert'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import moment from'moment'
import AttachmentItem from '../Attachment/AttachmentItem'
import Tooltip from '@material-ui/core/Tooltip'
import {
  messageApi,
  attachmentApi,
  ticketApi,
  getToken
} from '../../services/Api'

const styles = theme => ({
  root: {
    padding: 16,
    marginBottom: 20
  },
  body: {
    padding: 16,
    maxWidth: '100%'
  },
  title: {
    textOverflow: 'ellipsis'
  },
  date: {
    fontSize: 12,
    color: 'gray'
  }
})

function MessageCard({classes, ...props}) {
  const [item, setItem] = useState()
  const [attachments, setAttachments] = useState([])
  const [inlineAttachments, setInlineAttachments] = useState([])
  const [message, setMessage] = useState()
  const [canReply, setCanReply] = useState(false)
  const [anchorEl, setAnchorEl] = useState()
  const printRef = useRef()

  const getCurrentId = (params) => {
    if (params.childId) {
      return params.childId
    }
    return params.id
  }

  useEffect(() => {
    async function fetchData() {
      const id = getCurrentId(props.match.params)
      const response = await messageApi.getMessage(id)
      if (response.ok) {
        if (response.data.isDraft) {
          props.history.push(`/messages-edit?id=${response.data.id}`)
        }
        attachmentApi.getAttachments(response.data.id)
        .then(responseAttachments => {
          if (responseAttachments.ok) {
            setInlineAttachments(responseAttachments.data.filter(a => a.isInline))
            setAttachments(responseAttachments.data.filter(a => !a.isInline))
          }
        })
        if (response.data.conversationId) {
          const responseTicket = await ticketApi.getOne(response.data.conversationId)
          if (responseTicket.ok) {
            const ticket = responseTicket.data
            setCanReply(ticket.statusId)
          }
        }
        setItem(response.data)
      } else {
        setMessage('Falha ao obter e-mail junto a Microsoft')
      }
    }
    fetchData()
  }, [props.history.location.pathname])

  const renderAttachements = () => {
    const failAttach = attachments.find(a => !a.contentType)
    const result = attachments.map((attachment) => {
      return (
        <AttachmentItem
          key={attachment.id}
          name={attachment.name}
          attachmentId={attachment.id}
          messageId={item.id}
          contentType={attachment.contentType}
          contentBytes={attachment.contentBytes}
          size={attachment.size}
          setMessage={setMessage} />
      )
    })
    return (
      <React.Fragment>
        {result}
        {failAttach &&
          <Grid item sm={12}>
            <Typography variant='body2' gutterBottom noWrap>
              * Identificamos um anexo não exibido, veja a mensagem original <a href={item.webLink} target='_blank'> aqui</a>
            </Typography>
          </Grid>}
      </React.Fragment>
    )
  }

  const createReply = async (id) => {
    const response = await messageApi.createReply(id, {ticketId: item.ticketId})
    if (response.ok) {
      props.history.push(`/messages-edit?id=${response.data.id}`)
    }
  }
  const createReplyAll = async (id) => {
    const response = await messageApi.createReplyAll(id, {ticketId: item.ticketId})
    if (response.ok) {
      props.history.push(`/messages-edit?id=${response.data.id}`)
    }
  }
  const createForward = async (id) => {
    const response = await messageApi.createForward(id)
    if (response.ok) {
      props.history.push(`/messages-edit?id=${response.data.id}`)
    }
  }

  function handleClick (event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose () {
    setAnchorEl(null)
  }

  function handleSanckbarClose () {
    setMessage('')
  }

  function handleShowOriginal () {
    handleClose()
    const fileLink = document.createElement('a')
    fileLink.href = item.webLink
    fileLink.target = '_blank'
    fileLink.click()
    fileLink.remove()
  }

  async function handleDownload () {
    handleClose()
    setMessage('Preparando download...')
    const anchor = document.createElement("a")
    document.body.appendChild(anchor)
    const file = messageApi.getMessageValue(item.id)
    const [header, token] = getToken()

    const headers = new Headers()
    headers.append(header, token)

    fetch(file, { headers })
      .then(response => response.blob())
      .then(blobby => {
        let objectUrl = window.URL.createObjectURL(blobby)

        anchor.href = objectUrl
        anchor.download = `${item.subject}.eml`
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)
        setMessage('')
      })
      .catch(e => {
        console.log(e)
        setMessage('')
      })
  }

  function replaceAll(str, search, replacement) {
    return str.split(search).join(replacement)
  }

  function processBody (body) {
    for (const inline of inlineAttachments) {
      const cssinfo = 'style="height: auto; max-width: 100%; object-fit: contain"'
      const base = `data:${inline.contentType};base64,${inline.contentBytes}" ${cssinfo}`
      body = replaceAll(body, `cid:${inline.contentId}`, base)
    }
    return body
  }

  return (!!item) ?
    (<Paper className={classes.root} ref={printRef}>
      <Grid container direction='row' justify="flex-start" spacing={16}>
        <Grid item sm={9}>
          <Tooltip title={item.subject} placement="bottom-end" enterDelay={1000}>
            <Typography variant="h6" gutterBottom noWrap display="inline">
              <Tooltip title='Voltar'>
                <IconButton onClick={() => props.history.goBack()}
                  aria-label="Voltar">
                  <ArrowBack />
                </IconButton>
              </Tooltip>
              {item.subject}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item sm={3}>
          <Grid container justify="flex-end">
            <Tooltip
              title={
                canReply
                ? 'Responder'
                : 'É necessário definir status no ticket para responder'}>
              <div>
                <IconButton onClick={() => createReply(item.id)}
                  disabled={!canReply}
                  aria-label="Responder">
                  <Reply />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip
              title={
                canReply
                ? 'Responder para todos'
                : 'É necessário definir status no ticket para responder a todos'}>
              <div>
                <IconButton onClick={() => createReplyAll(item.id)}
                  disabled={!canReply}
                  aria-label="Responder para todos">
                  <ReplyAll />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title='Encaminhar'>
              <IconButton onClick={() => createForward(item.id)}
                aria-label="Encaminhar">
                <Forward />
              </IconButton>
            </Tooltip>
            <Tooltip title='Menu'>
              <IconButton onClick={handleClick}
                aria-label="Menu">
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <ReactToPrint
                trigger={() => <MenuItem onClick={handleClose}>Imprimir</MenuItem>}
                content={() => printRef.current}
              />
              <MenuItem onClick={handleShowOriginal}>Mostrar Original</MenuItem>
              <MenuItem onClick={handleDownload}>Download</MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Grid>
            <ListItem
              alignItems="center">
              <ListItemAvatar>
                <Avatar alt="User" />
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={item.from}
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary">
                    {`Para: ${item.toRecipients}`}
                  </Typography>
                }
              />
            </ListItem>
          </Grid>
          <Grid className={classes.date}>
            {moment(item.createdDateTime).format('DD/MM/YYYY HH:mm:ss')}
          </Grid>
        </Grid>
        <Grid container justify="flex-start">
          {item.hasAttachments && renderAttachements(item.id)}
        </Grid>
        <Grid container justify="flex-start" className={classes.body}>
          <div dangerouslySetInnerHTML={{__html: processBody(item.body)}} />
        </Grid>
        <Snackbar
          autoHideDuration={6000}
          onClose={handleSanckbarClose}
          message={message} />
      </Grid>
    </Paper>)
    : <Grid container direction='row' justify="center" spacing={16}>
        <CircularProgress size={24} />
      </Grid>
}

export default withStyles(styles)(MessageCard)
