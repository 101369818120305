import React from "react";
import { ListApiWithDelete } from "../../lib/Api";
import { loopbackFilters } from "../../lib/Api/loopback";
import { List } from "../../lib/CrudComponents";
import Filter from "../../lib/CrudComponents/Filter";
import Grid from "@material-ui/core/Grid";
import { TextField } from "final-form-material-ui";
import SelectField from "../../lib/Fields/Select";
import { Field } from "react-final-form";
import { userMailApi } from "../../services/Api";

function UserMailFilter(props) {
  return (
    <Filter labels={{ find: "Buscar", clear: "Limpar" }}>
      <Grid spacing={24} container>
        <Grid item sm={6}>
          <Field fullWidth component={TextField} label="Email" name="email" />
        </Grid>
        <Grid item sm={6}>
          <Field
            fullWidth
            hideEmpty
            component={SelectField}
            options={[
              { value: "user", label: "Usuário" },
              { value: "admin", label: "Administrador" },
            ]}
            label="Permissão"
            name="realm"
          />
        </Grid>
      </Grid>
    </Filter>
  );
}

export function UserMailsList(props) {
  const listOptions = {
    defaultOrder: "id DESC",
    fields: {
      id: {
        label: "Id",
      },
      email: {
        label: "Email",
      },
      realm: {
        label: "Permissão",
        format: (r) => (r === "admin" ? "Administrador" : "Usuário"),
      },
    },
  };

  const getPageDecorator = async ({ getPage }) => {
    const items = await getPage();
    return items;
  };

  const handleDeleteClick = async (userMail, handleDelete) => {
    const user = JSON.parse(localStorage["user"]);
    if (userMail.id !== user.userId) {
      return handleDelete(userMail);
    }
    return { ok: false, message: "Operação não permitida!" };
  };

  return (
    <ListApiWithDelete
      filtersConfig={{
        email: loopbackFilters.ilike,
      }}
      getPageDecorator={getPageDecorator}
      api={userMailApi}
    >
      {({ getPage, handleDelete, getCount }) => (
        <div>
          <UserMailFilter />
          <List
            {...props}
            withPaper
            notShowUndo
            onClickEdit={(item) => props.history.push(`/userMails/${item.id}`)}
            onClickNew={() => props.history.push("/userMails/new")}
            deleteItem={(item) => handleDeleteClick(item, handleDelete)}
            getPage={getPage}
            getCount={getCount}
            listOptions={listOptions}
          />
        </div>
      )}
    </ListApiWithDelete>
  );
}
