import React from 'react'
import Grid from '@material-ui/core/Grid'
import { CrudForm } from '../../lib/CrudComponents'
import Autocomplete from '../../lib/Fields/Autocomplete'
import { TextField } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { FormApi } from '../../lib/Api'
import { MountEntity, toOption } from '../../lib/Api/Entity'
import { departmentApi, userMailApi } from '../../services/Api'
import { loopbackFilters } from '../../lib/Api/loopback'

const loadUsers = async (inputValue) => {
  const response = await userMailApi.getList({
    where: {
      email: loopbackFilters.ilikeWithoutSplit(inputValue)
    },
    order: 'email asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('email'))
  }
  return []
}

function DepartmentForm({ classes, ...props }) {
  const validate = values => ({
    manager: values.manager ? undefined : 'Obrigatório',
    name: (values.name && values.name.trim()) ? undefined : 'Obrigatório'
  })

  const submitDecorator = async ({ id, data, handleSubmit }) => {
    const result = await handleSubmit({
      id,
      data: {
        ...data,
        managerId: data.manager.id,
        manager: undefined
      }
    })
    return result
  }

  const getItemDecorator = async ({ getItem }) => {
    const searchParams = new URLSearchParams(props.location.search)
    if (props.match.params.id === 'new' && searchParams.get('managerId')) {
      const response = await userMailApi.getOne(searchParams.get('managerId'))
      if (response.ok) {
        return {
          manager: toOption('email')(response.data)
        }
      }
      return
    }

    return MountEntity(props, departmentApi.getOne)
      .populate(({ data, id }) => ({
        manager: userMailApi.getOne(data.managerId)
      })).clean(['managerId'])
      .map(data => ({
        ...data,
        manager: toOption('email')(data.manager)
      }))
      .getItem()
  }

  return (
    <FormApi
      {...props}
      getItemDecorator={getItemDecorator}
      submitDecorator={submitDecorator}
      api={departmentApi} >
      {
        ({ handleSubmit, getItem }) => (
          <CrudForm
            validate={validate}
            history={props.history}
            getItem={getItem}
            onSubmit={handleSubmit}>
            <Grid direction='row' container spacing={16}>
              <Grid item sm={6}>
                <Field
                  fullWidth
                  component={TextField}
                  label='Nome'
                  name='name' />
              </Grid>
              <Grid item sm={6}>
                <Field
                  label='Gestor'
                  loadOptions={loadUsers}
                  component={Autocomplete}
                  name='manager' />
              </Grid>
            </Grid>
          </CrudForm>
        )
      }
    </FormApi>
  )
}

export default DepartmentForm
