import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#632036'
    },
    secondary: {
      main: '#A36474'
    },
  }
})
