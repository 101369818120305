import React from 'react'
import Grid from '@material-ui/core/Grid'
import { toOption } from '../../lib/Api/Entity'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import Filter from '../../lib/CrudComponents/Filter'
import Autocomplete from '../../lib/Fields/Autocomplete'
import {ListApi} from '../../lib/Api'
import {List} from '../../lib/CrudComponents'
import {loopbackFilters} from '../../lib/Api/loopback'
import {
  useParentChildrenNavigation,
  CrudTabs,
  CrudRoute
} from '../../lib/CrudComponents/CrudTabs'
import {
  departmentApi,
  sharedBoxApi,
  userMailApi
} from '../../services/Api'
import DepartmentForm from './DepartmentForm'
import { SharedBoxesList } from '../Admin/SharedBoxes'

const loadUsers = async (inputValue) => {
  const response = await userMailApi.getList({
    where: {
      email: loopbackFilters.ilikeWithoutSplit(inputValue)
    },
    order: 'email asc',
    limit: 10
  })
  if (response.ok) {
    return response.data.map(toOption('email'))
  }
  return []
}

function DepartmentFilter() {
  return (
    <Filter
      formatFilters={{ managerId: value => value.id }}
      labels={{find: 'Buscar', clear: 'Limpar'}}>
      <Grid spacing={24} container>
        <Grid item sm={6}>
          <Field
            fullWidth
            component={TextField}
            label='Nome'
            name='name'/>
        </Grid>
        <Grid item sm={6}>
          <Field
            label='Gestor'
            loadOptions={loadUsers}
            component={Autocomplete}
            name='managerId' />
        </Grid>
      </Grid>
    </Filter>
  )
}

export function DepartmentsList(props) {
  const listOptions = {
    defaultOrder: 'id DESC',
    fields: {
      id: {
        label: 'Id'
      },
      name: {
        label: 'Nome'
      },
      manager: {
        label: 'Gestor'
      }
    }
  }

  const getPageDecorator = async ({getPage}) => {
    const items = await getPage()
    const promises = items.map(async item => {
      const response = await userMailApi.getOne(item.managerId)
      if (response.ok) {
        item.manager = response.data.email
        item.managerId = undefined
        return item
      }
      return item
    })
    return Promise.all(promises)
  }

  const handleDeleteClick = async (department, handleDelete) => {
    const responseTickets = await sharedBoxApi.getList({where: {departmentId: department.id}, limit: 1})
    if (responseTickets.ok) {
      if (responseTickets.data.length === 0) {
        return handleDelete(department)
      }
    }
    return {ok: false, message: 'Existem SharedBox nesse departamento!'}
  }

  return (
    <ListApi
      filtersConfig={{
        name: loopbackFilters.ilike
      }}
      getPageDecorator={getPageDecorator}
      api={departmentApi}>
      {
        ({getPage, handleDelete, getCount}) => (
          <div>
            <DepartmentFilter />
            <List
              {...props}
              withPaper
              deleteItem={(item) => handleDeleteClick(item, handleDelete)}
              onClickEdit={(item) => props.history.push(`/departments/${item.id}`)}
              onClickNew={() => props.history.push('/departments/new')}
              getPage={getPage}
              getCount={getCount}
              listOptions={listOptions} />
          </div>
        )
      }
    </ListApi>
  )
}

export function DepartmentTabs(props) {
  const {current, navigate} = useParentChildrenNavigation(props, {mainPath: 'departments', availableTabs: ['userMails', 'sharedBoxes']})
  return (
    <CrudTabs {...props}
      mainPath='departments'
      current={current}
      tabs={[
        {value: '', label: 'Departamento'},
        {value: 'sharedBoxes', label: 'Shared Boxes'}
      ]}
      navigate={navigate}>
      <div>
        <CrudRoute
          render={() => <DepartmentForm {...props} />}/>
        <CrudRoute
          name='sharedBoxes'
          render={(props) => <SharedBoxesList {...props} onClickNew={() => props.history.push(`/sharedBoxesAdmin/new?departmentId=${props.match.params.id}`)} />} />
      </div>
    </CrudTabs>
  )
}
