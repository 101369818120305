import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import * as R from "ramda";
import Pagination from "./Pagination";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "../Common/Snackbar";
import { getRowsPerPage } from "../../utils/configUser";

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: 4 * theme.spacing.unit,
    bottom: 4 * theme.spacing.unit,
  },
});

class List extends React.Component {
  state = {
    fields: [],
    list: [],
    loading: true,
  };

  constructor(props) {
    super(props);
    const fields = this.createFields();
    this.state = { fields };
  }

  createFields = () => {
    const { fields } = this.props.listOptions;
    return R.keys(fields)
      .filter((s) => !!fields[s])
      .map((source) => {
        const field = fields[source];
        const { label = source, noOrder, type, format, getOne, key } = field;
        return {
          noOrder,
          key,
          getOne,
          format,
          label,
          source,
          type,
        };
      });
  };

  urlFilterToObject = (filter) => {
    if (!filter) return {};
    return filter.split(",").reduce((obj, keyValue) => {
      const [key, value] = keyValue.split("=");
      return { ...obj, [key]: value };
    }, {});
  };

  updatePage = async () => {
    const { getPage, getCount, location, listOptions } = this.props;
    const params = new URLSearchParams(location.search);
    const filter = this.urlFilterToObject(params.get("filters"));
    if (getPage) {
      this.setState({ loading: true });
      const page = +params.get("page") || 0;
      const rowsPerPage = +params.get("rows") || getRowsPerPage();
      const order = params.get("order") || listOptions.defaultOrder;
      const list = await getPage({ page, rowsPerPage, filter, order });
      this.setState({ list, loading: false });
    }
    if (getCount) {
      const count = await getCount({ filter });
      this.setState({ count });
    }
  };

  componentDidMount() {
    this.updatePage();
  }

  createCsv = (list, listOptions) => {
    const rows = list.map((obj) => Object.values(obj).join(","));
    const header = Object.keys(list[0])
      .map((key) => {
        const field = listOptions.fields[key];
        return field ? field.label : key;
      })
      .join(",");
    return [header].concat(rows).join("\r\n");
  };

  downloadFile = (csv, exportName) => {
    const element = document.createElement("a");
    const blob = new Blob(["\ufeff", csv]);
    element.setAttribute("href", URL.createObjectURL(blob));
    element.setAttribute("download", `${exportName}.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  downloadCsv = async () => {
    const {
      location,
      getPage,
      listOptions,
      exportName = new Date().toString(),
    } = this.props;
    const params = new URLSearchParams(location.search);
    const filter = this.urlFilterToObject(params.get("filters"));
    if (getPage) {
      const order = params.get("order") || listOptions.defaultOrder;
      const list = await getPage({ filter, order, rowsPerPage: 5000 });
      list.forEach((o) => delete o.removed);
      const csv = this.createCsv(list, listOptions);
      this.downloadFile(csv, exportName);
    }
  };

  async componentDidUpdate(prevProps) {
    if (this.props.location.search === prevProps.location.search) return;
    this.updatePage();
  }

  handleClickDelete = async (item) => {
    const { deleteItem } = this.props;
    if (deleteItem) {
      const {
        ok,
        message = "Item removed. Do you want to revert it?",
        undo,
      } = await deleteItem(item);
      if (ok) {
        this.setState({
          removedMessage: message,
          removedItem: item,
        });
        this.updatePage();
        this.undo = async () => {
          this.handleSnackbarClose();
          if (undo) {
            await undo();
            this.updatePage();
          }
        };
      } else {
        this.setState({
          removedMessage: message,
        });
      }
    }
  };

  undoAction = () => {
    if (this.state.removedItem && !this.props.notShowUndo) {
      return [
        <Button key="undo" color="secondary" size="small" onClick={this.undo}>
          DESFAZER
        </Button>,
      ];
    }
    return [];
  };

  handleSnackbarClose = () => {
    this.setState({ removedMessage: "", removedItem: null });
  };

  renderList = () => {
    const {
      listOptions: { defaultOrder = "" },
      classes,
      withPaper,
      onClickNew,
      getCount,
      onClickEdit,
      labelRowsPerPage = "Linhas por página",
      ofLabel = "de",
      history,
      location,
      deleteItem,
    } = this.props;
    const { list = [], loading } = this.state;
    const { fields, count = 0 } = this.state;
    const Container = withPaper ? Paper : (props) => <div {...props} />;
    return (
      <Container padding={0}>
        {loading ? <LinearProgress /> : null}
        <Table>
          <TableHead
            onDownloadClick={this.downloadCsv}
            defaultOrder={defaultOrder}
            columns={fields}
          />
          <TableBody
            onClickEdit={onClickEdit}
            onClickDelete={deleteItem ? this.handleClickDelete : null}
            list={list}
            fields={fields}
          />
          <TableFooter>
            <TableRow>
              <TableCell align="right" colSpan={fields.length} className="pl-0">
                {getCount && (
                  <Pagination
                    count={count}
                    history={history}
                    location={location}
                    ofLabel={ofLabel}
                    labelRowsPerPage={labelRowsPerPage}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.fab}
          onClick={onClickNew}
        >
          <AddIcon />
        </Fab>
      </Container>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderList()}
        <Snackbar
          message={this.state.removedMessage}
          action={this.undoAction()}
          onClose={this.handleSnackbarClose}
          autoHideDuration={6000}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(List);
